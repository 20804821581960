// textToSpeech.js
import axios from "axios";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY;
const textToSpeech = async (text, voice = 'en-US-Standard-C') => { // Default voice set as 'en-US-Standard-C'
  console.log("Text to send: ", text);
  const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${GOOGLE_API_KEY}`;
  const requestBody = {
    input: { text },
    voice: {
      languageCode: "en-US", // or your desired language code
      name: voice, // Using the passed voice parameter
    },
    audioConfig: { audioEncoding: "MP3" },
  };
  console.log("requestBody: ", requestBody);

  try {
    const response = await axios.post(url, requestBody);
    console.log("API Response:", response.data);
    return response.data.audioContent;
  } catch (error) {
    console.error("Error converting text to speech:", error);
    console.error("Response status:", error.response.status);
    console.error("Response data:", error.response.data);
    throw error;
  }
};

export default textToSpeech;
