import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { auth, sendPasswordResetEmail } from "../utils/firebase";
import "./PasswordReset.css";
import { Link } from "react-router-dom";

function PasswordReset() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };
  const handlePasswordReset = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(
        "Check your email (including spam or junk box) for your password reset link."
      );
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setError("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <div className="PWRS-password-reset-container">
      <div className="PWRS-header-container">
        <h1 className="PWRS-PassWord-header-title">iSpeakWell</h1>
        <h5 className="PWRS-tagline">
          Shape Your Resume, Cover Letter, and Interview Language with
          Professionalism, Confidence, and Distinction.
        </h5>

        <h3>Revise with OpenAI.</h3>
        <h2 className="PWRS-header-subtitle">Password Reset</h2>
      </div>
      <form className="PWRS-prform" onSubmit={handlePasswordReset}>
        <input
          className="PWRS-prinput"
          type="email"
          placeholder="Enter account email to retrieve password"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="PWRS-prbutton" type="submit">
          Submit
        </button>
      </form>
      <h3 className="PWRS-link-text">
        Changed your mind? No problem...Back to{" "}
        <Link className="PWRS-LogLink" to="/login">
          Login
        </Link>
      </h3>
      {message && <p className="PWRS-message">{message}</p>}
      {error && <p className="PWRS-error-message">{error}</p>}
      <div className="PWRS-links-section">
        <button className="PWRS-link-button" onClick={() => navigateTo("/about")}>
          About
        </button>
        <button
          className="PWRS-link-button"
          onClick={() => navigateTo("/careers")}
        >
          Careers
        </button>
        <button
          className="PWRS-link-button"
          onClick={() => navigateTo("/tokens")}
        >
          Tokens
        </button>
        <button
          className="PWRS-link-button"
          onClick={() => navigateTo("/contactus")}
        >
          Contact Us
        </button>
        <button className="PWRS-link-button" onClick={() => navigateTo("/login")}>
          Login
        </button>
      </div>
    </div>
  );
}

export default PasswordReset;
