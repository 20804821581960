import React from "react";
import { useNavigate } from "react-router-dom";
import "./AccountDeleted.css";

function AccountDeleted() {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="ACCTDEL-Container">
      <h1 className="ACCTDEL-header-title">iSpeakWell</h1>
      <h5 className="ACCTDEL-tagline">
        Shape Your Resume, Cover Letter, and Interview Language with
        Professionalism, Confidence, and Distinction.
      </h5>
      <h1 className="ACCTDEL-Message-Title">Account Deleted</h1>
      <p className="ACCTDEL-Message">Your account has been successfully deleted.</p>
      <div className="ACCTDEL-links-section">
        <button
          className="ACCTDEL-link-button"
          onClick={() => navigateTo("/about")}
        >
          About
        </button>
        <button
          className="ACCTDEL-link-button"
          onClick={() => navigateTo("/careers")}
        >
          Careers
        </button>
        <button
          className="ACCTDEL-link-button"
          onClick={() => navigateTo("/tokens")}
        >
          Tokens
        </button>
        <button
          className="ACCTDEL-link-button"
          onClick={() => navigateTo("/contactus")}
        >
          Contact Us
        </button>
        <button
          className="ACCTDEL-link-button"
          onClick={() => navigateTo("/login")}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default AccountDeleted;
