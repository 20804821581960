import React from 'react';
import CookieConsent from 'react-cookie-consent';

import "./CookieConsentComponent.css"
const CookieConsentComponent = () => {
  const handleAccept = () => { 
    console.log("Cookies accepted");
    // Additional logic upon acceptance can be added here
  };

  const handleDecline = () => {
    console.log("Cookies declined");
    // Additional logic upon decline can be added here
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="userCookieConsent"
      style={{ background: "#2B373B", textAlign: "left" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "5px" }}
      declineButtonStyle={{ fontSize: "13px", borderRadius: "5px" }}
      expires={150}
      onAccept={handleAccept}
      onDecline={handleDecline}
      enableDeclineButton
    >
      This website uses cookies to enhance the user experience.{" "}
      <span style={{ fontSize: "10px" }}>
        Learn more in our <a href="/cookie-policy" style={{ color: "#ffd700" }}>Cookie Policy</a>.
      </span>
    </CookieConsent>
  );
};

export default CookieConsentComponent;
