import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import UnsplashImage from "./UnsplashImage.jpg"; // Ensure the path and file extension are correct

const Home = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="HP-landing-container">
      <h1 className="HP-conversation-header-title">iSpeakWell</h1>
      <h5 className="HP-tagline">
        Shape Your Resume, Cover Letter, and Interview Language with
        Professionalism, Confidence, and Distinction.
      </h5>
      <h3>Revise with OpenAI.</h3>
      <button onClick={goToLogin} className="HP-cta-button">
        Get Started
      </button>
      <div className="HP-new-section">
        <div className="HP-row row-1">
          <h1>
            Try it out with <strong><u>2 FREE tokens</u></strong>!
          </h1>
        </div>
        <div className="HP-row row-1">
          <h3>Each Token Gives You</h3>
        </div>
        <div className="HP-row row-2">
          <div className="HP-itema">
            <h3>1 conversation</h3>
            <h3>(25 moves)</h3>
          </div>
          <div className="HP-OR">OR</div>
          <div className="HP-itema">
            <h3>1 Resume</h3>
            <h3>&</h3>
            <h3>1 Cover Letter</h3>
          </div>
          <div className="HP-OR">OR</div>
          <div className="HP-itema">
            <h3>1 Interview</h3>
            <h3>(5 Questions)</h3>
          </div>
        </div>
        <div className="HP-row row-3">
          <span className="HP-arrow">&#8595;</span>
        </div>
        <div className="HP-row row-1">
          <h3>Sign Up, Login, then Select a Task to Get Started</h3>
        </div>
        <div className="HP-row row-4"></div>
        <div className="HP-row row-5">
          <div className="HP-image-container">
            <img
              src={UnsplashImage}
              alt="A stylish chair and a laptop computer."
              className="HP-unsplash-image"
            />
          </div>
        </div>
        <button onClick={goToLogin} className="HP-cta-button">
          Let's Get Started
        </button>
        <div className="HP-links-section">
          <button className="HP-link-button" onClick={() => navigateTo("/about")}>
            About
          </button>
          <button
            className="HP-link-button"
            onClick={() => navigateTo("/careers")}
          >
            Careers
          </button>
          <button className="HP-link-button" onClick={() => navigateTo("/tokens")}>
            Tokens
          </button>
          <button
            className="HP-link-button"
            onClick={() => navigateTo("/contactus")}
          >
            Contact Us
          </button>
          <button className="HP-link-button" onClick={() => navigateTo("/login")}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
