import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import iSpeakWellLoginPage from "./iSpeakWellLoginPage.png";
import iSpeakWellPostLoginMenu from "./iSpeakWellPostLoginMenu.png";
import iSpeakWellNavigationMenu from "./iSpeakWellNavigationMenu.png";
import iSpeakWellResumeRevisorSavedWork from "./iSpeakWellResumeRevisorSavedWork.png";
import iSpeakWellJobDescriptionModal from "./iSpeakWellJobDescriptionModal.png";
import iSpeakWellResumeRevisorResumeInput from "./iSpeakWellResumeRevisorResumeInput.png";
import iSpeakWellJobDescriptionInput from "./iSpeakWellJobDescriptionInput.png";
import iSpeakWellResumeKeywords from "./iSpeakWellResumeKeywords.png";
import iSpeakWellJobDescriptionKeywords from "./iSpeakWellJobDescriptionKeywords.png";
import iSpeakWellResumeAnalysis from "./iSpeakWellResumeAnalysis.png";
import iSpeakWellMissingDetails from "./iSpeakWellMissingDetails.png";
import iSpeakWellAIJobCoachAvatar from "./iSpeakWellAIJobCoachAvatar.png";
import iSpeakWellRevisedResume from "./iSpeakWellRevisedResume.png";
import iSpeakWellTailoredCoverLetterandLikelyFit from "./iSpeakWellTailoredCoverLetterandLikelyFit.png";
import iSpeakWellResumeandCoverLetterSaveandDownload from "./iSpeakWellResumeandCoverLetterSaveandDownload.png";
import iSpeakWellInterviewTips from "./iSpeakWellInterviewTips.png";
import iSpeakWellAIAvatarInterviewer from "./iSpeakWellAIAvatarInterviewer.png";
import iSpeakWellInterviewInput from "./iSpeakWellInterviewInput.png";
import iSpeakWellInterviewInputandData from "./iSpeakWellInterviewInputandData.png";
import iSpeakWellInterviewPracticeResponseAnalysis from "./iSpeakWellInterviewPracticeResponseAnalysis.png";
import iSpeakWellConversationPracticeTopicSelectorandRoleSetter from "./iSpeakWellConversationPracticeTopicSelectorandRoleSetter.png";
import iSpeakWellMilestonesforConversationDevelopment from "./iSpeakWellMilestonesforConversationDevelopment.png";
import iSpeakWellAIAvatarConversationPartnerandInputs from "./iSpeakWellAIAvatarConversationPartnerandInputs.png";
import iSpeakWellAIAvatarResponse from "./iSpeakWellAIAvatarResponse.png";
import iSpeakWellAdmin from "./iSpeakWellAdmin.png";
import iSpeakWellAdmin2 from "./iSpeakWellAdmin2.png";
import iSpeakWellPurchasetokens from "./iSpeakWellPurchasetokens.png";
import iSpeakWellPayments from "./iSpeakWellPayments.png";
import iSpeakWellPrivacyPolicy from "./iSpeakWellPrivacyPolicy.png";
import iSpeakWellTermsOfService from "./iSpeakWellTermsOfService.png";
import "./SlideShow.css";

const images = [
  iSpeakWellLoginPage,
  iSpeakWellPostLoginMenu,
  iSpeakWellNavigationMenu,
  iSpeakWellResumeRevisorSavedWork,
  iSpeakWellJobDescriptionModal,
  iSpeakWellResumeRevisorResumeInput,
  iSpeakWellJobDescriptionInput,
  iSpeakWellResumeKeywords,
  iSpeakWellJobDescriptionKeywords,
  iSpeakWellResumeAnalysis,
  iSpeakWellMissingDetails,
  iSpeakWellAIJobCoachAvatar,
  iSpeakWellRevisedResume,
  iSpeakWellTailoredCoverLetterandLikelyFit,
  iSpeakWellResumeandCoverLetterSaveandDownload,
  iSpeakWellInterviewTips,
  iSpeakWellAIAvatarInterviewer,
  iSpeakWellInterviewInput,
  iSpeakWellInterviewInputandData,
  iSpeakWellInterviewPracticeResponseAnalysis,
  iSpeakWellConversationPracticeTopicSelectorandRoleSetter,
  iSpeakWellMilestonesforConversationDevelopment,
  iSpeakWellAIAvatarConversationPartnerandInputs,
  iSpeakWellAIAvatarResponse,
  iSpeakWellAdmin,
  iSpeakWellAdmin2,
  iSpeakWellPurchasetokens,
  iSpeakWellPayments,
  iSpeakWellPrivacyPolicy,
  iSpeakWellTermsOfService,
];

const imgTitle = [
  "Login Page",
  "Post Login Menu",
  "Navigation Menu",
  "Resume Revisor Saved Work",
  "Job Description Modal",
  "Resume Revisor Resume Input",
  "Job Description Input",
  "Resume Keywords",
  "Job Description Keywords",
  "Resume Analysis",
  "Missing Details",
  "AI Job Coach Avatar",
  "Revised Resume",
  "Tailored Cover Letter and Likely Fit",
  "Resume and Cover Letter Save and Download",
  "Interview Tips",
  "AI Avatar Interviewer",
  "Interview Input",
  "Interview Input and Data",
  "Interview Practice Response Analysis",
  "Conversation Practice Topic Selector and Role Setter",
  "Milestones fo rConversation Development",
  "AI Avatar Conversation Partner and Inputs",
  "AI Avatar Response",
  "Admin",
  "Admin 2",
  "Purchase tokens",
  "Payments",
  "PrivacyPolicy",
  "Terms OfService",
];

function Slideshow() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isPlaying) {
      const timer = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 5000); // Change image every 5 seconds

      return () => clearInterval(timer); // Clean up the interval on component unmount
    }
  }, [isPlaying, currentImageIndex]);

  const goToNext = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const goToPrevious = () => {
    setCurrentImageIndex(
      (currentImageIndex - 1 + images.length) % images.length
    );
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="SlideShow">
      <h1 className="SS-supheader-title">iSpeakWell</h1>
      <h5 className="SS-tagline">
        Shape Your Resume, Cover Letter, and Interview Language with
        Professionalism, Confidence, and Distinction.
      </h5>
      <h5 className="SS-tagline">Project Components</h5>
      <h5 className="SS-tagline">{imgTitle[currentImageIndex]}</h5>
      <img
        className="SS-projectImages"
        src={images[currentImageIndex]}
        alt="Slideshow"
        style={{
          maxWidth: "100%",
          maxHeight: "400px", // You can adjust this value
          objectFit: "contain",
          width: "auto",
          height: "auto",
        }}
      />
      
      <div classNAme="SS-Buttons">
        <button onClick={goToPrevious}>Previous</button>
        <button onClick={togglePlayPause}>
          {isPlaying ? "Pause" : "Play"}
        </button>
        <button onClick={goToNext}>Next</button>
      </div>
      <div className="SS-links-section">
        <button
          className="SS-link-button"
          onClick={() => navigateTo("/about")}
        >
          About
        </button>
        <button
          className="SS-link-button"
          onClick={() => navigateTo("/careers")}
        >
          Careers
        </button>
        <button
          className="SS-link-button"
          onClick={() => navigateTo("/tokens")}
        >
          Tokens
        </button>
        <button
          className="SS-link-button"
          onClick={() => navigateTo("/contactus")}
        >
          Contact Us
        </button>
        <button className="SS-link-button" onClick={() => navigateTo("/login")}>
          Login
        </button>
      </div>
    </div>
  );
}

export default Slideshow;
