import "./VB.css";
import React, { useState, useEffect } from "react";
import VoiceBotIframe from "./VoiceBotiFrame";
import axios from "axios";
import YouTube from "react-youtube";
import BackofMonitorImage from "./BackofMonitor.png"; // Importing the image
import SeattoRideImage from "./SeattoRide.png"; // Importing the image
import Plant1 from "./Plant1.png";
import Plant2 from "./Plant2.png";
import StudioMic from "./StudioMic.png";
import Bookshelf1 from "./Bookshelf1.png";
import Curtains from "./Curtains.png";
import StudioMonitor from "./StudioMonitor.png";
import ComfyChair from "./ComfyChair.png";
import StageLights from "./StageLights.png";

function VB() {
  const [videoId, setVideoId] = useState('M0w2eQ-FcEA'); // Default video ID
  const [playlistVideos, setPlaylistVideos] = useState([]);
  const [isPlaylistVisible, setIsPlaylistVisible] = useState(false);
  const [boxVisible, setBoxVisible] = useState(false);
  const [boxTitle, setBoxTitle] = useState('');
  const [transcript, setTranscript] = useState(''); // State for the transcript
  const [transcriptContent, setTranscriptContent] = useState(''); // Store the content of the transcript for display
  const [currentTime, setCurrentTime] = useState(0); // State to track current time of the video
  const [player, setPlayer] = useState(null); // Store the YouTube player instance

  const API_KEY = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY2;
  const PLAYLIST_ID = 'PLblaVxcRe39xzkN6H28tnHOJt_fhfZzqo';

  // Fetch playlist videos on component mount
  useEffect(() => {
    const fetchPlaylistVideos = async () => {
      let nextPageToken = '';
      let allVideos = [];

      do {
        const response = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${PLAYLIST_ID}&maxResults=50&key=${API_KEY}${nextPageToken ? `&pageToken=${nextPageToken}` : ''}`);
        const data = await response.json();
        allVideos = allVideos.concat(data.items);
        nextPageToken = data.nextPageToken;
      } while (nextPageToken);

      setPlaylistVideos(allVideos);
    };

    fetchPlaylistVideos();
  }, [API_KEY]);

  const handlePlayerReady = (event) => {
    console.log("Player is ready.");
    setPlayer(event.target);
    handlePlayClick();
  };

  const handlePlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      console.log("Video is playing.");
    }
  };

  const handleInputChange = (e) => {
    setVideoId(e.target.value);
  };

  const handlePlayClick = async () => {
    if (videoId) {
      const idRegex = /(?:youtube\.com(?:[^/\n\s]+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n]{11})/;
      const match = videoId.match(idRegex);
      const extractedVideoId = match ? match[1] : videoId;
      setVideoId(extractedVideoId);
      await fetchTranscript(extractedVideoId); // Fetch the transcript
    }
  };

  const fetchTranscript = async (videoId) => {
    try {
      const response = await axios.get(`https://transcript.ispeakwell.ca/transcript?video_id=${videoId}`);
      if (response.data && response.data.transcript) {
        const formattedTranscript = format_transcript_with_start_times(response.data.transcript);
        setTranscript(formattedTranscript);
      } else {
        setTranscript("No transcript available for this video.");
      }
    } catch (error) {
      console.error("Error fetching transcript:", error);
      setTranscript("Error fetching transcript.");
    }
  };

  const handleVideoClick = async (id) => {
    setVideoId(id);
    await fetchTranscript(id); // Fetch the transcript for the clicked video
  };

  const togglePlaylistVisibility = () => {
    setIsPlaylistVisible(prev => !prev);
  };

  const handleButtonClick = (title) => {
    if (title === "Text") {
      if (boxVisible && boxTitle === "Transcript") {
        setBoxVisible(false);
      } else {
        setBoxTitle("Transcript");
        setTranscriptContent(transcript);
        setBoxVisible(true);
      }
    } else {
      if (boxVisible && boxTitle === title) {
        setBoxVisible(false);
      } else {
        setBoxTitle(title);
        setBoxVisible(true);
      }
    }
  };

  const handleHideClick = () => {
    setBoxVisible(false);
  };

  function formatTimestamp(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return `${hours > 0 ? `${hours}:` : ''}${minutes < 10 && hours > 0 ? `0${minutes}` : minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const handleTimestampClick = (timestamp) => {
    if (player) {
      player.seekTo(timestamp, true);
    }
  };

  function format_transcript_with_start_times(transcriptText) {
    const lines = transcriptText.split('\n');

    return lines
      .map(line => {
        const regex = /^\[(\d+(\.\d+)?)s\]\s*(.*)$/;
        const match = line.match(regex);

        if (match) {
          const timestamp = parseFloat(match[1]);
          const text = match[3];
          const formattedTimestamp = formatTimestamp(timestamp);

          return (
            <div 
              key={timestamp}
              className="transcript-line" 
              style={{ margin: '8px 0', cursor: 'pointer' }}
              onClick={() => handleTimestampClick(timestamp)}
            >
              <span style={{ fontWeight: 'bold' }}>[{formattedTimestamp}]</span> {text}
            </div>
          );
        }
        return null; 
      })
      .filter(line => line);  // Remove null entries
  }

  const opts = {
    height: '250',
    width: '445',
    playerVars: {
      autoplay: 0, // Video does not autoplay by default
      enablejsapi: 1,
    },
  };

  return (
    <div className="VB-container">
      <h1 className="VB-header-title">The iSpeakWell Podcast</h1>
      <div className="main-container">
        <div className="video-input-container">
          <div className="video-container">
            <YouTube
              videoId={videoId}
              opts={opts}
              onReady={handlePlayerReady}
              onStateChange={handlePlayerStateChange}
            />
            <div className="input-container">
            <input
              type="text"
              value={videoId}
              onChange={handleInputChange}
              placeholder="Enter YouTube video ID"
            />
          <button className="VidPlayButton" onClick={handlePlayClick}>Get</button>
        </div>
            <div className="playlist-container">
              <h3 className="playlist-title" onClick={togglePlaylistVisibility} style={{ cursor: 'pointer', color: 'grey' }}>
                Sources
              </h3>
              <div className={`playlist-list ${isPlaylistVisible ? 'fade-in' : 'fade-out'}`}>
                {isPlaylistVisible && (
                  <ul>
                    {playlistVideos.map(video => (
                      <li 
                        key={video.id} 
                        onClick={() => handleVideoClick(video.snippet.resourceId.videoId)}
                        style={{ cursor: 'pointer', color: 'grey' }}
                      >
                        {video.snippet.title}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <img className="Curtains"
        src={Curtains}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "93%", 
          height: "140%", 
          opacity: "0.40",
          objectFit: "cover",
          objectPosition: "top",
        }}
        
        />
        <img className="stagelights"
        src={StageLights}
        alt="Overlay"
        style={{
          position: "absolute",
          top: -120,
          left: -75,
          width: "110%", 
          height: "40%", 

        }}
        />

        <img className="comfychair"
        src={ComfyChair}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 475,
          left: 115,
          width: "40%", 
          height: "80%", 
          objectFit: "cover",
          objectPosition: "top",
        }}
        />
        <img className="plant1"
        src={Plant1}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 400,
          left: 570,
          width: "25%", 
          height: "80%", 
          objectFit: "cover",
          objectPosition: "top",
        }}
        />
        <img className="bookshelf"
        src={Bookshelf1}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 200,
          left: 1100,
          width: "50%", 
          height: "80%", 
          objectFit: "cover",
          objectPosition: "top",
        }}
        />
        <img className="seat"
        src={SeattoRideImage}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 450,
          left: 500,
          width: "65%", 
          height: "50%", 
          objectFit: "cover",
          objectPosition: "top",
        }}
        />
        <img className="studiomonitor"
        src={StudioMonitor}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 300,
          left: -100,
          width: "18%", 
          height: "100%", 
        }}
        />
        
        <img className="studiomic"
        src={StudioMic}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 1115,
          width: "40%", 
          height: "100%", 
        }}
        />
        <div className="VB-VoiceBot-container">
          
          <VoiceBotIframe />
        </div>
      </div>

      <div className="button-grid">
        {["Vocabulary", "Grammar", "Text", "Idiom", "Rhetoric", "Culture", "Talk", "Fluency", "Progress"].map((title) => (
          <button key={title} className="square-button" onClick={() => handleButtonClick(title)}>{title}</button>
        ))}
      </div>

      <div className={`info-box ${boxVisible ? 'fade-in' : 'fade-out'}`} onClick={handlePlayClick} style={{
        position: 'fixed',
        bottom: 10,
        right: 10,
        width: '25%',
        height: '70%',
        backgroundColor: 'rgba(255, 255, 255, 0.33)',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        opacity: boxVisible ? 1 : 0,
        transition: 'opacity 0.5s ease, transform 0.5s ease',
        transform: boxVisible ? 'translateY(0)' : 'translateY(20px)',
        borderTopLeftRadius: '15px',
      }}>
        <h2 style={{ textAlign: 'left', color: 'white' }}>{boxTitle}</h2>
        {boxTitle === "Transcript" && (
          <div style={{ color: 'white', overflowY: 'auto', maxHeight: '80%' }}>
            {transcriptContent}
          </div>
        )}
        <button onClick={handleHideClick} style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          cursor: 'pointer',
          color: 'white',
        }}>
          X
        </button>
      </div>
        
      <img className="monitor"
        src={BackofMonitorImage}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 675,
          left: 590,
          width: "55%", 
          height: "36%", 
          objectFit: "cover",
          objectPosition: "top",
        }}
      />
      <img className="plant2"
        src={Plant2}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 425,
          left: 1400,
          width: "25%", 
          height: "78%", 
          objectFit: "cover",
          objectPosition: "top", 

        }}
        />
      
    </div>
  );
}

export default VB;
