import "./ConversationRecord.css";
import React, { useState, useEffect } from "react";
import { db } from "../utils/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";

const ConversationRecord = () => {
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState("");
  const [conversations, setConversations] = useState("");
  const [error, setError] = useState("");
  const numberOfLines = (conversations.match(/\n/g) || []).length + 1;

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await fetchGoals();
      } else {
        setError("User is not authenticated");
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchGoals = async (user) => {
    try {
      const querySnapshot = await getDocs(collection(db, "conversations"));
      const goalsSet = new Set();
      querySnapshot.forEach((doc) => {
        const goal = doc.data().selectedGoal;
        if (goal) {
          goalsSet.add(goal);
        }
      });
      setGoals(Array.from(goalsSet));
    } catch (err) {
      setError("Error fetching goals: " + err.message);
    }
  };

  const fetchConversations = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user && selectedGoal) {
        const q = query(
          collection(db, "conversations"),
          where("userId", "==", user.uid),
          where("selectedGoal", "==", selectedGoal)
        );
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          let allConversations = "";
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const timestamp = data.timestamp ? data.timestamp.toDate().toLocaleString() : "No timestamp";
            const desiredRole = data.desiredRole || "No desired role";
            const selectedMileStones = data.selectedMileStones ? data.selectedMileStones.map(ms => `• ${ms}`).join('\n') : "No milestones";
  
            allConversations += `Desired Role: ${desiredRole}\n\n`;
            allConversations += `Selected Goal: ${data.selectedGoal}\n\n`;
            allConversations += `Selected Milestones:\n${selectedMileStones}\n\n`;
            allConversations += `Timestamp: ${timestamp}\n\n`;
            allConversations += "Messages:\n";
  
            if (data.messages && data.messages.length > 0) {
              data.messages.forEach((message) => {
                if (typeof message === 'string') {
                  allConversations += message + "\n\n";
                } else if (message.text) {
                  allConversations += message.author + ": " + message.text + "\n\n";
                }
              });
            }
            allConversations += "----------------------------------------\n\n";
          });
          setConversations(allConversations);
        } else {
          setConversations("");
          setError("No conversations found for the selected goal");
        }
      } else {
        setError("User not authenticated or goal not selected");
      }
    } catch (err) {
      setError("Failed to fetch conversations: " + err.message);
    }
  };
  
  return (
    <div className="ConvRec-Container">
      <h2 className="ConvRec-Header">Review Conversations</h2>

      <div className="ConvRec-SG-Selector-Container">
        <label>Goal:</label>
        <select
          className="ConvRec-SG-Selector"
          value={selectedGoal}
          onChange={(e) => setSelectedGoal(e.target.value)}
        >
          {goals.map((goal) => (
            <option key={goal} value={goal}>
              {goal}
            </option>
          ))}
        </select>
      </div>

      <button className="ConvRec-ConvFetch" onClick={fetchConversations}>
        Fetch Conversations
      </button>

      <div className="ConvRec-Conv-Container">
        {error && <p style={{ color: "red" }}>{error}</p>}
        <textarea className="ConvRec-Convo" value={conversations} readOnly rows={numberOfLines}/>
      </div>
    </div>
  );
};

export default ConversationRecord;

