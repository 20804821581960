import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom'; // Import useNavigate
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate(); // Use useNavigate hook for navigation

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setIsAuthenticated(true);
      } else {
        // User is signed out
        if (localStorage.getItem('accountDeleted') === 'true') {
          // If accountDeleted flag is set, navigate to account deleted page
          localStorage.removeItem('accountDeleted'); // Clear the flag
          navigate('/accountdeleted'); // Navigate to the account deleted page
        } else {
          setIsAuthenticated(false);
        }
      }
    });

    return () => unsubscribe(); // Unsubscribe on unmount
  }, [navigate]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or some loading indicator
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
