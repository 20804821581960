import React from 'react';
import "./CookiePolicy.css";
import { useNavigate } from "react-router-dom";


const CookiePolicy = () => {
    const navigate = useNavigate();
    
    const navigateTo = (path) => {
        navigate(path);
      };

  return (
    <div className="cookie-policy">
      <h1 className="CPolicy-Header">Cookie Policy</h1>

      <section>
        <h2 className="CPolicy-SubHeader">Introduction</h2>
        <p className="CPolicy-Paragraph">Our website uses cookies to enhance your browsing experience. This policy explains how we use cookies and how you can control them.</p>
      </section>

      <section>
        <h2 className="CPolicy-SubHeader">What Are Cookies?</h2>
        <p className="CPolicy-Paragraph">Cookies are small text files stored on your device when you access most websites on the internet. They are used to remember your preferences and improve your browsing experience.</p>
      </section>

      <section>
        <h2 className="CPolicy-SubHeader">Types of Cookies Used</h2>
        <ul className="CPolicy-UnorderedList">
          <li className="CPolicy-ListItem">Strictly Necessary Cookies: These are essential for the operation of our site, enabling basic functions like page navigation.</li>
          <li className="CPolicy-ListItem">Performance Cookies: We use these cookies to analyze how our visitors use our website and to monitor website performance.</li>
          <li className="CPolicy-ListItem">Functionality Cookies: These cookies remember your preferences, like your language or region.</li>
          <li className="CPolicy-ListItem">Targeting/Advertising Cookies: These cookies are used to deliver advertisements more relevant to you.</li>
        </ul>
      </section>

      <section>
        <h2 className="CPolicy-SubHeader">Managing Cookies</h2>
        <p className="CPolicy-Paragraph">You can manage your cookie preferences by adjusting your browser settings. You can also withdraw your consent to non-essential cookies through our website's cookie settings panel.</p>
      </section>

      <section>
        <h2 className="CPolicy-SubHeader">Third-party Cookies</h2>
        <p className="CPolicy-Paragraph">We use third-party services that may set cookies on your device. For example, we embed YouTube videos on our site. These third-party services have their own cookie policies.</p>
      </section>

      <section>
        <h2 className="CPolicy-SubHeader">Policy Changes</h2>
        <p className="CPolicy-Paragraph">Our Cookie Policy may change from time to time. We encourage users to review our policy periodically.</p>
      </section>

      <section>
        <h2 className="CPolicy-SubHeader">Contact Information</h2>
        <p className="CPolicy-Paragraph">If you have any questions about our use of cookies, please contact us at info@fluentenglish.ca.</p>
      </section>
      <div className="CPolicy-links-section">
          <button className="CPolicy-link-button" onClick={() => navigateTo("/about")}>About</button>
          <button className="CPolicy-link-button" onClick={() => navigateTo("/careers")}>Careers</button>
          <button className="CPolicy-link-button" onClick={() => navigateTo("/tokens")}>Tokens</button>
          <button className="CPolicy-link-button" onClick={() => navigateTo("/contactus")}>Contact Us</button>
          <button className="CPolicy-link-button" onClick={() => navigateTo("/login")}>Login</button>
        </div>
    </div>
  );
};

export default CookiePolicy;
