// Tokens.js
import React, { useContext, useState, useEffect } from "react";
import { TokenContext } from "./tokenContext"; // import TokenContext
import { useNavigate } from "react-router-dom";
import "./Purchase.css";
import { getAuth } from "firebase/auth";
import { db } from "../utils/firebase";
import {
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { logout } from "../utils/firebase";
import Spinner from "./Spinner";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe('pk_live_51OUJ8RJs2pb8XH4PAC5jjh24HPSXDsYquxF8dkFwZJqzM3upN2rq56lWPNisXHcYZH7ifvwjY8ggZzroyS5pXHmD00Wb3XO5Zn');
//const stripePromise = loadStripe(  "pk_test_51OUJ8RJs2pb8XH4PIPjNQmICJFFURBUJ2CcYmcfH24Gf2NLDXp9Cb6Uw3UdxQYciqlYkG3TqHEEgo0LdjybUtb84001AJe74KW");

function Purchase() {
  const { tokens, setTokens } = useContext(TokenContext); // Use TokenContext
  const [currentTokens, setCurrentTokens] = useState(0);
  const [selectedTokens, setSelectedTokens] = useState(tokens); // State for selected token amount
  const navigate = useNavigate();
  console.log("selectedTokens: ", selectedTokens);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const goAway = () => {
    navigate("/menu");
  };

  useEffect(() => {
    const fetchTokens = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userRef = doc(db, "users", user.uid);
        try {
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setCurrentTokens(userDoc.data().tokens || 0);
          }
        } catch (error) {
          console.error("Error fetching token count: ", error);
        }
      }
    };

    fetchTokens();
  }, [currentTokens, setCurrentTokens]);


  const handleTokenPurchase = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    setIsPurchasing(true);
    setTokens(selectedTokens);
    if (user) {
      const userRef = doc(db, "users", user.uid);
      try {
        // Update desiredPurchase in Firestore
        await updateDoc(userRef, {
          desiredPurchase: parseInt(selectedTokens, 10), // Ensure it's an integer
          paying: true,
        });
        const stripe = await stripePromise;
        try {
          console.log("SelectedTokens: ", selectedTokens);
          const response = await fetch(
            "https://fluency.ispeakwell.ca/api/create-checkout-session",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ quantity: selectedTokens }),
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();
          console.log("Session data:", data);

          if (data.sessionId) {
            const result = await stripe.redirectToCheckout({
              sessionId: data.sessionId,
            });

            if (result.error) {
              console.log(result.error.message);
            }
          } else {
            console.log("No session ID returned from the server");
          }
        } catch (error) {
          console.error("Error in handleTokenPurchase:", error.message);
        }
      } catch (error) {
        console.error("Error setting desiredPurchase:", error);
      }
    }
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  const InterViewPractice = () => {
    // Navigate to the InterviewPractice page
    navigate("/interview-practice");
  };

  const Dashboard = () => {
    navigate("/resumerevisor");
  };

  const ConversationPractice = () => {
    navigate("/conversationPractice");
  };

  const Purchase = () => {
    navigate("/purchase");
  };

  const admin = () => {
    navigate("/admin");
  };

  const Logout = async () => {
    await logout();
    navigate("/login");
  };

  const TermsofService = () => {
    navigate("/termsofservice");
  };

  const Privacy = () => {
    navigate("/privacypolicy");
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItemButton onClick={Purchase}>
          <ListItemText primary={`Tokens: ${currentTokens}`} />
        </ListItemButton>
        <ListItemButton onClick={Dashboard}>
          <ListItemText primary="Resume Revisor" />
        </ListItemButton>
        <ListItemButton onClick={InterViewPractice}>
          <ListItemText primary="Interview Practice" />
        </ListItemButton>
        <ListItemButton onClick={ConversationPractice}>
          <ListItemText primary="Conversation Practice" />
        </ListItemButton>
        <ListItemButton onClick={admin}>
          <ListItemText primary="Admin" />
        </ListItemButton>
        <ListItemButton onClick={Logout}>
          <ListItemText primary="Logout" />
        </ListItemButton>
        <ListItemButton onClick={TermsofService}>
          <ListItemText primary="Terms of Service" />
        </ListItemButton>
        <ListItemButton onClick={Privacy}>
          <ListItemText primary="Privacy" />
        </ListItemButton>
      </List>
    </div>
  );

  return (
    <div className="PCH-Purchase-container">
      <h1 className="PCH-header-title">iSpeakWell</h1>
      <h5 className="PCH-tagline">
        Shape Your Resume, Cover Letter, and Interview Language with
        Professionalism, Confidence, and Distinction.
      </h5>
      <h1>Purchase Tokens</h1>
      <h2>You currently have {currentTokens} tokens.</h2>
      <input
        className="PCH-TokenNum"
        type="number"
        value={selectedTokens}
        onChange={(e) => setSelectedTokens(e.target.value)}
        min="0"
      />

      {isPurchasing ? (
        <Spinner />
      ) : (
        <div className="ButtonstoNav">
          <button onClick={handleTokenPurchase}>Purchase Tokens</button>
          <button className="PCH-menuGoer" onClick={goAway}>
            Menu
          </button>
        </div>
      )}

      <h2 className="PCH-under-construction-text">What Are Tokens For?</h2>
      <p className="PCH-divMsg">
        Tokens enable you to perform the resume or speech work you want to do.
        With tokens, you can revise your resume and get a matching cover letter.
        You can do a practice interview. You can also have a practice
        conversation to develop your speaking fluency.
      </p>
      <h4>Tokens Required for Conversation Practice</h4>
      <p className="PCH-divMsg">
        One (1) token is required for Conversation Practice (1,200 words
        approximately)
      </p>
      <h4>Tokens Required for Resume Revision and Cover Letter</h4>
      <p className="PCH-divMsg">
        One (1) token is required for Resume Revision and Cover Letter
      </p>
      <h4>Tokens Required for Interview Practice</h4>
      <p className="PCH-divMsg">
        One (1) token is required for Interview Practice (5 questions)
      </p>
      <nav className="PCH-logout-nav">
        {/* Hamburger Menu Icon */}
        <IconButton className="PCH-menu-icon" onClick={toggleDrawer(true)}>
          <MenuIcon
            style={{
              boxShadow: "0 0 50px #000000, 0 0 20px #ffffff",
              // Add additional styles if needed
            }}
          />
        </IconButton>

        {/* Drawer for Mobile View */}
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={toggleDrawer(false)}
          className="PCH-custom-drawer"
        >
          {list()}
        </Drawer>
      </nav>
      <div className="PCH-links-section">
        <button
          className="PCH-link-button"
          onClick={() => navigateTo("/about")}
        >
          About
        </button>
        <button
          className="PCH-link-button"
          onClick={() => navigateTo("/careers")}
        >
          Careers
        </button>
        <button
          className="PCH-link-button"
          onClick={() => navigateTo("/tokens")}
        >
          Tokens
        </button>
        <button
          className="PCH-link-button"
          onClick={() => navigateTo("/contactus")}
        >
          Contact Us
        </button>
        <button
          className="PCH-link-button"
          onClick={() => navigateTo("/login")}
        >
          Login
        </button>
      </div>
    </div>
  );
}
export default Purchase;
