import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PageExpired.css';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../utils/firebase'; // Ensure this points to your Firestore instance

function PageExpired() {
  const navigate = useNavigate();

  useEffect(() => {
    const resetDesiredPurchase = async () => {
      const auth = getAuth();
      const user = auth.currentUser; // Get current user

      if (user) {
        const userRef = doc(db, 'users', user.uid); // Reference to user's document in Firestore
        try {
          await updateDoc(userRef, {
            desiredPurchase: 0 // Resetting desiredPurchase
          });
          console.log('desiredPurchase reset successfully');
        } catch (error) {
          console.error('Error resetting desiredPurchase: ', error);
        }
      }
    };

    resetDesiredPurchase();
  }, []);

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="PGEX-Menu-container">
      <h1 className="PGEX-header-title">iSpeakWell</h1>
      <h5 className="PGEX-tagLine">
        Shape Your Resume, Cover Letter, and Interview Language with Professionalism, Confidence, and Distinction.
      </h5>
      <hr />
      <h1 className = "PGEX-Task-Menu">Page Expired</h1>
      <hr />
      <h2 className="PGEX-Task-Menu">Select a task to continue</h2>
      <div className="PGEX-BTN-HLDR">
        <button onClick={() => navigateTo("/resumerevisor")}>Resume and Cover Letter</button>
        <button onClick={() => navigateTo("/interview-practice")}>Interview Practice</button>
        <button onClick={() => navigateTo("/conversationpractice")}>Conversation Practice</button>
      </div>
      <div className="PGEX-BTN-HLDR"><button onClick={() => navigateTo("/purchase")}>Buy Tokens</button></div>
    </div>
  );
}

export default PageExpired;
