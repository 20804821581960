import React, { useState } from "react";
import { db } from "../utils/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { deleteUser } from "firebase/auth";
import { useAuth } from "./AuthContext";
import "./DeleteAccount.css";

const DeleteAccount = () => {
  const [error, setError] = useState("");
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { currentUser } = useAuth();

  const handleDeleteProcess = async () => {
    setIsProcessing(true);
    setShowConfirmDialog(false); // Hide confirmation dialog
    setError(""); // Clear any previous errors

    try {
      localStorage.setItem('accountDeleted', 'true');
      // Delete user data from Firestore first
      await deleteDoc(doc(db, "users", currentUser.uid));

      // Then, delete the user account from Firebase Authentication
      await deleteUser(currentUser);

      // If successful, show success message and wait 10 seconds before redirecting
      setError("");
      setIsProcessing(false);
    } catch (error) {
      setError("Failed to delete account. Please try again.");
      setIsProcessing(false);
    }
  };

  return (
    <div className="delete-account-container">
      <h3>Delete Your Account</h3>
      {error && <p className="error-message">{error}</p>}
      {isProcessing && <p className="success-message">Processing your request...</p>}
      {!isProcessing && !error && (
        <>
          <button onClick={() => setShowConfirmDialog(true)}>
            Delete Account
          </button>
          {showConfirmDialog && (
            <div className="confirmation-dialog">
              <p><strong>So sorry to see you go!</strong></p>
              <p>Are you sure you want to delete your account? This action cannot be undone.</p>
              <button onClick={handleDeleteProcess}>Yes, delete my account</button>
              <button onClick={() => setShowConfirmDialog(false)}>No, keep my account</button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DeleteAccount;
