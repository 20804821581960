import axios from 'axios';

const serverLog = async (message) => {
  try {
    await axios.post('https://audlister.ispeakwell.ca/log', { message });
    console.log('Log sent to server:', message);
  } catch (error) {
    console.error('Error sending log to server:', error);
  }
};

export default serverLog;