import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, createUserWithEmailAndPassword } from "../utils/firebase";
import { Link } from "react-router-dom";
import "./Signup.css";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../utils/firebase"; // Import your Firestore instance

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const navigateTo = (path) => {
    navigate(path);
  };

  const isStrongPassword = (password) => {
    return /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password);
  };

  const handleSignup = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!isStrongPassword(password)) {
      setError(
        "Password should be at least 8 characters, contain a number and an uppercase letter."
      );
      return;
    }

    try {
      if (!firstName || !lastName || !email || !password || !confirmPassword) {
        alert("All fields are required!");
        return;
      }
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Add a new document in collection "users" with the user's UID
      await setDoc(doc(db, "users", userCredential.user.uid), {
        email: email,
        firstName: firstName,
        lastName: lastName,
        role: "user",
        tokens: 2,
        desiredPurchase: 0,
        paying: false,
      });
      navigate("/menu");
    } catch (error) {
      console.error("Error signing up:", error);
      setError(error.message);
    }
  };

  return (
    <div className="SU-signup-container">
      <h1 className="SU-supheader-title">iSpeakWell</h1>
      <h5 className="SU-tagline">
        Shape Your Resume, Cover Letter, and Interview Language with
        Professionalism, Confidence, and Distinction.
      </h5>
      <h3>Revise with OpenAI.</h3>
      <form className="SU-SignUp-Input" onSubmit={handleSignup}>
        <h4>Please enter your name:</h4>
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <h4>Please enter your email:</h4>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="new-password"
        />
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button type="button" className="SUButtons" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? "Hide" : "Show"} Password
        </button>
        <button className="SUButtons" type="submit">Sign Up</button>
      </form>
      {error && <p className="SU-error-message">{error}</p>}
      <h3 className="SU-link-text">
        Already have an account?{" "}
        <Link className="SU-LogLink" to="/login">
          Login
        </Link>
      </h3>
      <div className="SU-links-section">
        <button
          className="PWRS-link-button"
          onClick={() => navigateTo("/about")}
        >
          About
        </button>
        <button
          className="SU-link-button"
          onClick={() => navigateTo("/careers")}
        >
          Careers
        </button>
        <button
          className="SU-link-button"
          onClick={() => navigateTo("/tokens")}
        >
          Tokens
        </button>
        <button
          className="SU-link-button"
          onClick={() => navigateTo("/contactus")}
        >
          Contact Us
        </button>
        <button className="SU-link-button" onClick={() => navigateTo("/login")}>
          Login
        </button>
      </div>
    </div>
  );
}

export default Signup;
