import "./ConversationTopicsandGoals.css";
import React, { useState } from "react";

const ConversationTopicsandGoals = ({
  onDesiredRoleChange,
  onSelectedGoalChange,
  onSelectedMilestonesChange,
}) => {
  const workRelatedGoals = [
    "Greetings and Politeness: Learning to greet appropriately and use polite language sets the tone for social interactions.",
    "Turn-Taking: Understanding the dynamics of conversational turn-taking, including when to speak and when to listen.",
    "Body Language: Recognizing non-verbal cues like gestures, facial expressions, and posture that complement speech.",
    "Active Listening: Developing the ability to actively listen and respond thoughtfully to what others are saying.",
    "Expressing Gratitude: Knowing how to express thanks and appreciation genuinely.",
    "Small Talk: Mastering the art of small talk to initiate and maintain casual conversations.",
    "Empathy: Learning to empathize with others and express understanding and support.",
    "Apologies: Understanding when and how to apologize sincerely.",
    "Expressing Agreement and Disagreement: Effectively conveying agreement or disagreement without causing offense.",
    "Introducing Oneself: Articulating one's identity and background in various settings.",
    "Giving Compliments: Providing compliments that are genuine and appropriate.",
    "Requesting Favors: Making requests politely and effectively.",
    "Maintaining Eye Contact: Knowing when and how to maintain eye contact during conversations.",
    "Code-Switching: Adapting language and communication style based on the social context and the interlocutor.",
    "Using Appropriate Titles and Pronouns: Employing appropriate titles and pronouns when addressing people with different levels of familiarity and respect.",
    "Cultural Sensitivity: Recognizing and respecting cultural differences in communication norms.",
    "Negotiation Skills: Developing effective negotiation and persuasion techniques.",
    "Storytelling: Mastering the art of storytelling to engage and captivate an audience.",
    "Active Participation in Meetings: Contributing effectively in meetings, discussions, and group settings.",
    "Resolving Conflicts: Learning conflict resolution strategies that maintain relationships.",
    "Giving Feedback: Providing constructive feedback in a considerate manner.",
    "Tone and Emphasis: Understanding how tone, emphasis, and intonation affect the meaning of a message.",
    "Polite Declines: Expressing refusal or disagreement politely and diplomatically.",
    "Adapting to Formal and Informal Language: Knowing when to use formal or informal language.",
    "Understanding Idioms and Metaphors: Recognizing and using idiomatic expressions and metaphors in conversation.",
    "Dealing with Taboos: Being aware of sensitive topics and avoiding offensive language.",
    "Expressing Sympathy and Condolences: Offering comfort and support during difficult times.",
    "Group Dynamics: Understanding how language and communication shape group dynamics.",
    "Storytelling in Professional Settings: Using narratives effectively in business and professional contexts.",
    "Networking Skills: Building and maintaining professional relationships through effective communication.",
    "Understanding Humor: Recognizing humor, sarcasm, and irony in language and using them appropriately.",
    "Using Fillers and Pauses: Learning how to use fillers like `u` and `um` and pauses effectively during speech.",
    "Negotiating Social Hierarchies: Navigating language to address and interact with individuals of varying status or authority.",
    "Sensitivity to Gender Pronouns: Respecting and using appropriate gender pronouns in inclusive language.",
    "Expressing Surprise and Amazement: Conveying surprise or amazement in a culturally appropriate manner.",
    "Dealing with Interruptions: Responding tactfully to interruptions during conversations.",
    "Maintaining Confidentiality: Knowing when and how to keep sensitive information private.",
    "Phone and Email Etiquette: Adhering to proper etiquette in phone conversations and written communication.",
    "Active Participation in Online Discussions: Engaging constructively in virtual discussions and forums.",
    "Managing Group Discussions: Facilitating and participating in group discussions to ensure effective communication.",
    "Cross-Cultural Communication: Understanding nuances of communication when interacting with people from different cultures.",
    "Handling Miscommunication: Addressing and resolving misunderstandings in a diplomatic manner.",
    "Expressing Concern and Empathy: Communicating care and concern for someone experiencing challenges.",
    "Responding to Compliments: Gracefully accepting compliments and acknowledging them.",
    "Elevator Pitch: Crafting a concise and compelling introduction for professional networking.",
    "Dealing with Criticism: Responding positively to constructive criticism and feedback.",
    "Resolving Mispronunciations: Handling mispronunciations and correcting them gracefully.",
    "Expressing Doubt and Uncertainty: Conveying uncertainty without undermining one's credibility.",
    "Handling Disagreements in a Group: Contributing to productive discussions in the face of differing opinions.",
    "Managing Conversational Dominance: Balancing the conversation to avoid monopolizing or being overly passive.",
    "Using Fillers Sparingly: Minimizing the use of filler words and hesitations for clearer communication.",
    "Acknowledging Cultural Norms: Recognizing and respecting cultural norms when communicating across borders.",
    "Building Rapport: Establishing a positive connection with others through conversational techniques.",
    "Interpreting Body Language: Understanding the subtleties of body language and non-verbal cues.",
    "Maintaining Professional Boundaries: Knowing when to keep conversations strictly professional.",
    "Expressing Regret: Conveying genuine remorse when a mistake has been made.",
    "Dealing with Inquiries: Responding effectively to questions while respecting privacy.",
    "Active Reading and Listening: Developing critical reading and listening skills for deeper understanding.",
    "Giving Public Speeches: Mastering public speaking skills for presentations and speeches.",
    "Providing Clear Instructions: Delivering concise and understandable instructions to others.",
    "Using Analogies: Effectively using analogies and comparisons to clarify concepts or ideas.",
    "Resolving Linguistic Taboos: Recognizing and addressing linguistic taboos or offensive language use.",
    "Expressing Agreement Diplomatically: Politely acknowledging agreement with someone while adding value to the conversation.",
    "Conversational Cues: Recognizing when it's appropriate to change the topic or wrap up a conversation.",
    "Cultural References: Understanding and making culturally relevant references in conversations.",
    "Expressing Curiosity: Asking questions to express genuine interest in a topic or another person.",
    "Dealing with Multilingual Environments: Navigating conversations in environments where multiple languages are spoken.",
    "Matching Register and Tone: Adjusting the level of formality or informality based on the context and audience.",
    "Providing Clarity in Writing: Writing clear and concise messages, emails, and documents.",
    "Handling Social Media Communication: Understanding the etiquette of communication on social media platforms.",
    "Acknowledging Personal Boundaries: Respecting personal space and boundaries in various situations.",
    "Maintaining Professional Etiquette: Adhering to professional conduct and etiquette in the workplace.",
    "Cultural References in Humor: Recognizing and using culturally specific humor appropriately.",
    "Sensitivity to Disabilities: Communicating with empathy and respect when interacting with individuals with disabilities.",
    "Managing Personal Presentation: Presenting oneself professionally through language and appearance.",
    "Navigating Hierarchies in the Workplace: Understanding how language and communication vary based on workplace hierarchies.",
    "Expressing Urgency: Communicating the importance of time-sensitive matters effectively.",
    "Using Jargon Appropriately: Applying industry-specific or technical jargon when relevant and understandable.",
    "Addressing Elders and Authority Figures: Showing respect through appropriate language and gestures.",
    "Persuasive Communication: Mastering techniques for persuasive speech and writing.",
    "Handling Sensitive Conversations: Navigating difficult or sensitive topics with care and empathy.",
    "Public Relations and Crisis Communication: Responding to crises with effective communication strategies.",
    "Giving Constructive Feedback to Superiors: Providing feedback upward in a professional and diplomatic manner.",
    "Managing Speech Rate: Adjusting speech rate to match the comprehension level of the listener.",
    "Interpreting Cultural Symbols: Understanding and respecting symbols and gestures across cultures.",
    "Using Proper Titles in Writing: Addressing individuals formally in written communication.",
    "Multimodal Communication: Incorporating visual aids, gestures, and speech effectively.",
    "Balancing Online and Offline Communication: Managing communication in both virtual and physical environments.",
    "Handling Group Facilitation: Leading and moderating group discussions and meetings.",
    "Expressing Ethical Concerns: Addressing ethical issues and dilemmas in a principled manner.",
    "Respecting Privacy and Confidentiality Online: Safeguarding personal and sensitive information in digital communication.",
    "Articulating Complex Ideas: Breaking down complex concepts into understandable language.",
    "Inclusive Language: Using language that is inclusive of all genders, ethnicities, and identities.",
    "Dealing with Unintended Offense: Apologizing and learning from unintentional offensive language.",
    "Expressing Appreciation for Feedback: Acknowledging and valuing feedback, even if it's critical.",
    "Managing Public Speaking Anxiety: Overcoming nervousness when speaking in public.",
    "Navigating Cross-Generational Communication: Adjusting communication style when interacting with different age groups.",
    "Using Metacommunication: Reflecting on and discussing communication processes for improvement.",
    "Advocating for Oneself: Assertively expressing needs, desires, and boundaries.",
    "Continual Adaptation: Recognizing that sociolinguistic competence is an ongoing process of learning and adapting to evolving social and cultural norms.",
  ];

  const sociolinguisticMilestones = [
    {
      lesson: "Greetings and Politeness",
      milestones: [
        "Practice simple greetings (e.g., 'Hello,' 'Hi,' 'Good morning') in different contexts with friends and family.",
        "Learn formal greetings (e.g., 'Good afternoon, sir/ma'am') for professional settings and use them appropriately.",
        "Incorporate polite phrases (e.g., 'Please,' 'Thank you') into daily conversations consistently.",
        "Experiment with culturally specific greetings and polite expressions when interacting with people from diverse backgrounds.",
        "Master the ability to initiate conversations with polite greetings tailored to specific situations, showing respect and warmth.",
      ],
    },
    {
      lesson: "Turn-Taking",
      milestones: [
        "Practice basic turn-taking in casual conversations with family and friends, waiting for pauses before responding.",
        "Learn to use non-verbal cues, like nodding and maintaining eye contact, to indicate you're listening and ready to speak.",
        "Engage in group discussions and meetings, taking turns effectively in more complex conversations.",
        "Develop the skill to gracefully yield the floor when necessary, allowing others to speak without interruption.",
        "Master the art of facilitating group discussions, ensuring everyone has an opportunity to contribute.",
      ],
    },
    {
      lesson: "Body Language",
      milestones: [
        "Start by paying attention to your own body language, ensuring it aligns with your words and intentions.",
        "Practice mirroring the body language of others to establish rapport and connection in conversations.",
        "Learn to interpret common non-verbal cues, such as crossed arms or eye contact, to better understand others.",
        "Develop the skill to adapt your body language to different cultural norms and social contexts.",
        "Master the ability to use body language strategically to convey confidence, empathy, and engagement.",
      ],
    },
    {
      lesson: "Active Listening",
      milestones: [
        "Start by focusing on listening without interrupting in everyday conversations.",
        "Practice summarizing what others have said to ensure you've understood correctly.",
        "Learn to ask clarifying questions to show your engagement and interest in the speaker's perspective.",
        "Develop the skill to listen actively in challenging or emotionally charged conversations.",
        "Master the art of empathetic listening, demonstrating understanding and support in all types of interactions.",
      ],
    },
    {
      lesson: "Expressing Gratitude",
      milestones: [
        "Begin by saying 'thank you' for everyday courtesies and favors from family and friends.",
        "Practice expressing gratitude with sincerity and specificity, mentioning what you appreciate.",
        "Learn to write heartfelt thank-you notes or emails for more significant gestures of kindness.",
        "Develop the skill to express gratitude even in challenging situations or conflicts.",
        "Master the art of acknowledging and showing appreciation for others in both personal and professional settings effectively.",
      ],
    },
    {
      lesson: "Small Talk",
      milestones: [
        "Start by initiating simple small talk topics like weather, hobbies, or weekend plans.",
        "Practice transitioning from small talk to more meaningful conversations smoothly.",
        "Learn to adapt small talk topics to different contexts and audiences, tailoring your approach.",
        "Develop the skill to make small talk enjoyable and engaging for both you and your conversation partner.",
        "Master the art of using small talk as a powerful tool for building rapport and connecting with others effectively.",
      ],
    },
    {
      lesson: "Empathy",
      milestones: [
        "Begin by actively listening to others and acknowledging their feelings with phrases like 'I understand.'",
        "Practice putting yourself in others' shoes, considering their perspectives, and responding with empathy.",
        "Learn to offer support and comfort to friends or colleagues in times of need.",
        "Develop the skill to empathize with individuals from diverse backgrounds and experiences.",
        "Master the ability to demonstrate deep empathy by actively helping and supporting others in difficult situations.",
      ],
    },
    {
      lesson: "Apologies",
      milestones: [
        "Start by recognizing when you've made a mistake and acknowledging it in a simple, sincere manner.",
        "Practice taking responsibility for your actions and apologizing without making excuses.",
        "Learn to offer restitution or make amends when appropriate in addition to a verbal apology.",
        "Develop the skill to apologize in challenging or sensitive situations, taking care not to further hurt others.",
        "Master the art of delivering heartfelt and effective apologies, repairing relationships and trust.",
      ],
    },
    {
      lesson: "Expressing Agreement and Disagreement",
      milestones: [
        "Begin by expressing agreement or disagreement politely in everyday conversations.",
        "Practice providing reasons or explanations for your agreement or disagreement to foster understanding.",
        "Learn to respectfully challenge ideas or opinions while maintaining a constructive tone.",
        "Develop the skill to navigate and resolve disagreements in group discussions or debates gracefully.",
        "Master the ability to engage in nuanced discussions, acknowledging valid points from others while expressing your viewpoint effectively.",
      ],
    },
    {
      lesson: "Introducing Oneself",
      milestones: [
        "Start with a basic self-introduction, including your name and a simple greeting.",
        "Practice introducing yourself with additional information, such as your background or profession.",
        "Learn to tailor your self-introduction to different settings, highlighting relevant details.",
        "Develop the skill to introduce yourself confidently in formal or professional situations.",
        "Master the art of captivating self-introductions, leaving a memorable impression in any context.",
      ],
    },
    {
      lesson: "Giving Compliments",
      milestones: [
        "Start by giving simple compliments to friends or acquaintances on their appearance or achievements.",
        "Practice offering specific compliments that highlight the unique qualities or efforts of the person.",
        "Learn to give compliments gracefully without making the recipient uncomfortable or embarrassed.",
        "Develop the skill to give compliments in a professional setting, recognizing colleagues' accomplishments.",
        "Master the art of sincere and well-timed compliments that genuinely uplift and motivate others.",
      ],
    },
    {
      lesson: "Requesting Favors",
      milestones: [
        "Begin by making straightforward requests to friends or family for simple favors or assistance.",
        "Practice framing requests politely, using phrases like 'Could you please?' or 'Would it be possible to...?'",
        "Learn to tailor your requests to the preferences and availability of the person you're asking.",
        "Develop the skill to request favors in professional settings with clarity and professionalism.",
        "Master the ability to negotiate and reciprocate favors effectively to maintain positive relationships.",
      ],
    },
    {
      lesson: "Maintaining Eye Contact",
      milestones: [
        "Start by consciously maintaining eye contact during one-on-one conversations with friends or family.",
        "Practice using appropriate eye contact in group settings, ensuring you engage with multiple participants.",
        "Learn when to break eye contact briefly for cultural or conversational norms without appearing disinterested.",
        "Develop the skill to use eye contact as a tool to convey confidence, attentiveness, and connection.",
        "Master the art of maintaining eye contact that feels natural and fosters a sense of trust and rapport.",
      ],
    },
    {
      lesson: "Code-Switching",
      milestones: [
        "Begin by observing how people adapt their language and communication style in various social contexts.",
        "Practice code-switching informally, adjusting your language when speaking with friends, family, and colleagues.",
        "Learn to navigate code-switching in multicultural settings, respecting different language norms and dialects.",
        "Develop the skill to seamlessly transition between languages and communication styles based on the interlocutor.",
        "Master the ability to code-switch effectively, enhancing communication and building connections across diverse groups.",
      ],
    },
    {
      lesson: "Using Appropriate Titles and Pronouns",
      milestones: [
        "Start by using common titles (e.g., Mr., Mrs., Dr.) and gender-neutral pronouns (e.g., they/them) in everyday interactions.",
        "Practice addressing individuals with appropriate titles and pronouns based on their preferences and identities.",
        "Learn to adapt your language to show respect and familiarity, using titles and pronouns accordingly.",
        "Develop the skill to navigate formal and informal language, addressing people with the appropriate level of respect.",
        "Master the art of using titles and pronouns inclusively, creating a welcoming and respectful environment for all.",
      ],
    },
    {
      lesson: "Cultural Sensitivity",
      milestones: [
        "Begin by exploring and learning about the cultural norms, customs, and practices of different groups.",
        "Practice showing respect for cultural differences in language and communication when interacting with diverse individuals.",
        "Learn to adapt your behavior and language to align with the cultural preferences of the people you engage with.",
        "Develop the skill to avoid cultural insensitivity or stereotypes, fostering cross-cultural understanding and harmony.",
        "Master the ability to communicate and collaborate effectively in multicultural environments while honoring diversity.",
      ],
    },
    {
      lesson: "Negotiation Skills",
      milestones: [
        "Start by practicing negotiation in everyday scenarios, such as deciding on dinner plans with friends.",
        "Practice articulating your needs and interests clearly and listening actively to others' perspectives.",
        "Learn negotiation techniques, including compromise, persuasion, and finding win-win solutions.",
        "Develop the skill to negotiate in professional settings, such as salary discussions or contract agreements.",
        "Master the art of skilled negotiation, achieving favorable outcomes while maintaining positive relationships.",
      ],
    },
    {
      lesson: "Storytelling",
      milestones: [
        "Begin by sharing personal anecdotes or stories with friends or family in casual conversations.",
        "Practice structuring stories with clear beginnings, middles, and endings to engage your audience effectively.",
        "Learn storytelling techniques, such as using vivid imagery and emotion to captivate listeners.",
        "Develop the skill to adapt storytelling to different contexts, including professional presentations and speeches.",
        "Master the ability to tell compelling stories that resonate with and inspire your audience.",
      ],
    },
    {
      lesson: "Active Participation in Meetings",
      milestones: [
        "Start by actively contributing ideas and insights during informal group discussions or family meetings.",
        "Practice taking on specific roles, such as note-taker or facilitator, to enhance meeting effectiveness.",
        "Learn effective meeting etiquette, including punctuality, active listening, and concise contributions.",
        "Develop the skill to lead and participate in formal business meetings, ensuring productive outcomes.",
        "Master the art of active participation, fostering collaboration and achieving meaningful results in meetings.",
      ],
    },
    {
      lesson: "Resolving Conflicts",
      milestones: [
        "Begin by addressing minor conflicts or disagreements in personal relationships through open communication.",
        "Practice listening to all sides of a conflict, identifying underlying issues, and seeking resolution together.",
        "Learn conflict resolution strategies, including compromise, negotiation, and mediation, for more complex disputes.",
        "Develop the skill to manage conflicts in professional settings, promoting cooperation and productivity.",
        "Master the ability to navigate and resolve conflicts effectively, preserving relationships and fostering growth.",
      ],
    },
    {
      lesson: "Giving Feedback",
      milestones: [
        "Start by providing positive feedback and compliments to build a foundation of trust and positivity.",
        "Practice delivering constructive feedback with a focus on specific behaviors or actions rather than personal traits.",
        "Learn to use the 'sandwich method,' where constructive feedback is sandwiched between positive feedback or praise.",
        "Develop the skill to give feedback in a private and confidential manner when necessary.",
        "Master the art of providing feedback that promotes growth and improvement while maintaining a supportive tone.",
      ],
    },
    {
      lesson: "Tone and Emphasis",
      milestones: [
        "Begin by paying attention to your own tone and emphasis in everyday conversations.",
        "Practice using tone and emphasis to convey emotions and intentions clearly in spoken and written communication.",
        "Learn how to modulate your voice and emphasize key points in public speaking or presentations.",
        "Develop the skill to adjust tone and emphasis based on the audience and context, effectively influencing the message's impact.",
        "Master the ability to use tone and emphasis to enhance the meaning and engagement of your communication.",
      ],
    },
    {
      lesson: "Polite Declines",
      milestones: [
        "Start by practicing polite refusals in low-stake situations with friends or acquaintances.",
        "Practice expressing disagreement diplomatically, using phrases like 'I see your point, but...' or 'I respectfully disagree.'",
        "Learn to decline invitations or requests with kindness and consideration, offering alternatives when possible.",
        "Develop the skill to gracefully decline professional opportunities or offers while maintaining a positive relationship.",
        "Master the art of polite declines, ensuring that your refusals leave a favorable impression and open communication channels.",
      ],
    },
    {
      lesson: "Adapting to Formal and Informal Language",
      milestones: [
        "Begin by observing and differentiating between formal and informal language used in various contexts.",
        "Practice using formal language when appropriate, such as in professional emails or formal settings.",
        "Learn to switch to informal language comfortably in casual conversations with friends and peers.",
        "Develop the skill to adapt your language register swiftly to match the formality level of the situation.",
        "Master the ability to navigate between formal and informal language seamlessly, enhancing communication effectiveness.",
      ],
    },
    {
      lesson: "Understanding Idioms and Metaphors",
      milestones: [
        "Start by familiarizing yourself with common idiomatic expressions and metaphors in your native language.",
        "Practice using idioms and metaphors in everyday conversations to add depth and nuance to your language.",
        "Learn to interpret idiomatic expressions and metaphors used by others, considering cultural and contextual factors.",
        "Develop the skill to use idioms and metaphors effectively in writing and public speaking to engage your audience.",
        "Master the art of incorporating idiomatic language naturally and creatively into your communication.",
      ],
    },
    {
      lesson: "Dealing with Taboos",
      milestones: [
        "Begin by recognizing and understanding topics considered taboo or sensitive in your culture or context.",
        "Practice avoiding offensive language and inappropriate humor related to taboo subjects.",
        "Learn to navigate conversations involving sensitive topics with empathy and care, respecting others' boundaries.",
        "Develop the skill to address taboos or sensitive issues when necessary while maintaining respectful and considerate language.",
        "Master the ability to promote open dialogue and understanding on taboo subjects through tactful communication.",
      ],
    },
    {
      lesson: "Expressing Sympathy and Condolences",
      milestones: [
        "Start by offering simple expressions of sympathy and comfort to friends or family during difficult times.",
        "Practice using empathetic language and gestures to convey your support effectively.",
        "Learn to write heartfelt messages or letters of condolence to provide comfort in times of loss.",
        "Develop the skill to express sympathy professionally and compassionately, such as in workplace settings.",
        "Master the art of offering genuine and sincere sympathy and condolences that provide solace to those in need.",
      ],
    },
    {
      lesson: "Group Dynamics",
      milestones: [
        "Begin by observing group dynamics in different social and professional settings.",
        "Practice active participation in group discussions, respecting the roles and contributions of others.",
        "Learn to facilitate and lead group discussions effectively, promoting collaboration and inclusivity.",
        "Develop the skill to address and manage conflicts within group dynamics constructively and diplomatically.",
        "Master the ability to shape and influence group dynamics positively through skilled communication and leadership.",
      ],
    },
    {
      lesson: "Storytelling in Professional Settings",
      milestones: [
        "Start by incorporating brief anecdotes or stories in business meetings or presentations.",
        "Practice structuring and delivering professional stories that support your message or objectives.",
        "Learn to use storytelling techniques to engage and persuade colleagues, clients, or stakeholders effectively.",
        "Develop the skill to adapt storytelling to various professional contexts, such as sales pitches or project proposals.",
        "Master the art of using storytelling as a powerful tool for conveying information, inspiring action, and achieving professional goals.",
      ],
    },
    {
      lesson: "Networking Skills",
      milestones: [
        "Begin by building connections with colleagues, peers, or mentors through casual networking events or social gatherings.",
        "Practice introducing yourself and engaging in conversations that showcase your expertise and interests.",
        "Learn effective networking strategies, including follow-up and maintaining relationships over time.",
        "Develop the skill to network in more formal or industry-specific events, conferences, or online platforms.",
        "Master the ability to leverage your network for professional growth, collaboration, and opportunities.",
      ],
    },
    {
      lesson: "Understanding Humor",
      milestones: [
        "Start by recognizing and appreciating different forms of humor in everyday conversations and media.",
        "Practice using humor, such as jokes and witty remarks, in appropriate contexts with friends and colleagues.",
        "Learn to distinguish between humor, sarcasm, and irony and use each form effectively and considerately.",
        "Develop the skill to adapt your humor to the preferences and sensitivities of your audience, avoiding offensive content.",
        "Master the art of using humor to build rapport, relieve tension, and enhance communication in various social situations.",
      ],
    },
    {
      lesson: "Using Fillers and Pauses",
      milestones: [
        "Begin by becoming aware of your use of fillers (e.g., 'uh,' 'um,' 'like') and pauses in speech.",
        "Practice using fillers and pauses purposefully to gather thoughts, emphasize points, or maintain engagement.",
        "Learn to reduce excessive filler usage and use pauses strategically for effect in public speaking or presentations.",
        "Develop the skill to speak fluently with minimal fillers while maintaining a natural flow in your speech.",
        "Master the ability to use fillers and pauses effectively, enhancing your overall communication clarity and impact.",
      ],
    },
    {
      lesson: "Negotiating Social Hierarchies",
      milestones: [
        "Start by observing and understanding social hierarchies in different contexts and cultures.",
        "Practice addressing individuals of varying status or authority appropriately, using titles and respectful language.",
        "Learn to navigate power dynamics in professional settings, balancing assertiveness and deference effectively.",
        "Develop the skill to communicate confidently with individuals in positions of authority while maintaining respect.",
        "Master the art of adapting your language and behavior to various social hierarchies, promoting harmonious interactions.",
      ],
    },
    {
      lesson: "Sensitivity to Gender Pronouns",
      milestones: [
        "Begin by educating yourself on gender pronouns and their importance in inclusive language.",
        "Practice using appropriate gender pronouns when addressing and referring to individuals based on their preferences.",
        "Learn to correct mistakes gracefully if you use the wrong pronoun and apologize sincerely when necessary.",
        "Develop the skill to create a respectful and inclusive environment by actively supporting gender diversity.",
        "Master the ability to consistently and confidently use correct gender pronouns in your language and interactions.",
      ],
    },
    {
      lesson: "Expressing Surprise and Amazement",
      milestones: [
        "Start by recognizing moments of surprise or amazement in everyday life and conversations.",
        "Practice conveying your genuine surprise or amazement through facial expressions, tone, and language.",
        "Learn culturally appropriate ways to express wonder and awe in various social settings.",
        "Develop the skill to respond spontaneously to surprising or impressive situations while maintaining courtesy.",
        "Master the art of expressing surprise and amazement effectively, fostering positive connections and reactions in others.",
      ],
    },
    {
      lesson: "Dealing with Interruptions",
      milestones: [
        "Begin by acknowledging interruptions with grace and patience in casual conversations.",
        "Practice using non-verbal cues, such as raised hands or gestures, to minimize interruptions in group discussions.",
        "Learn to address interruptions assertively but politely, redirecting the conversation back to the speaker.",
        "Develop the skill to navigate interruptions in professional settings, ensuring productive and respectful communication.",
        "Master the ability to handle interruptions tactfully while maintaining the flow and quality of conversations.",
      ],
    },
    {
      lesson: "Maintaining Confidentiality",
      milestones: [
        "Start by understanding the importance of confidentiality and privacy in different contexts, such as work and personal relationships.",
        "Practice keeping sensitive information private and refraining from sharing confidential details without consent.",
        "Learn to handle confidential information with discretion and integrity, even in challenging situations.",
        "Develop the skill to communicate diplomatically when asked about confidential matters without divulging secrets.",
        "Master the art of maintaining confidentiality, earning trust, and upholding ethical standards in communication.",
      ],
    },
    {
      lesson: "Phone and Email Etiquette",
      milestones: [
        "Begin by familiarizing yourself with common phone and email etiquette rules and norms in professional communication.",
        "Practice answering and making phone calls with professionalism and courtesy, including effective voicemail messages.",
        "Learn to compose clear and concise emails, using proper formatting, tone, and subject lines.",
        "Develop the skill to handle phone and email inquiries promptly and efficiently, demonstrating respect for others' time.",
        "Master the ability to maintain professional phone and email etiquette, enhancing your reputation in digital communication.",
      ],
    },
    {
      lesson: "Active Participation in Online Discussions",
      milestones: [
        "Start by actively engaging in online discussions and forums, respecting netiquette and community guidelines.",
        "Practice constructing well-thought-out and respectful comments or responses to contribute meaningfully.",
        "Learn to cite sources and provide evidence to support your arguments and opinions in online discussions.",
        "Develop the skill to navigate and respond to disagreements or criticisms online with civility and maturity.",
        "Master the art of constructive online participation, building a positive online presence and fostering productive discourse.",
      ],
    },
    {
      lesson: "Managing Group Discussions",
      milestones: [
        "Begin by observing effective group discussion facilitation and participation in different settings.",
        "Practice leading and moderating group discussions, ensuring equal participation and adherence to objectives.",
        "Learn to foster a collaborative atmosphere, encouraging diverse perspectives and respectful exchanges.",
        "Develop the skill to navigate challenging group dynamics, such as conflicts or disruptions, with poise and diplomacy.",
        "Master the ability to manage and contribute to group discussions skillfully, achieving desired outcomes and cohesion.",
      ],
    },
    {
      lesson: "Cross-Cultural Communication",
      milestones: [
        "Start by learning about different cultural communication norms and practices.",
        "Practice adapting your communication style when interacting with people from diverse cultural backgrounds.",
        "Learn to ask questions and actively listen to understand cultural perspectives and avoid misunderstandings.",
        "Develop the skill to communicate respectfully and effectively in cross-cultural interactions, considering nuances.",
        "Master the ability to build positive relationships and collaboration with individuals from various cultures.",
      ],
    },
    {
      lesson: "Handling Miscommunication",
      milestones: [
        "Begin by recognizing signs of miscommunication, such as confusion or disagreement, in conversations.",
        "Practice addressing miscommunications diplomatically, seeking clarification and offering alternative explanations.",
        "Learn to acknowledge your role in miscommunication and take responsibility for resolving it collaboratively.",
        "Develop the skill to de-escalate tense situations caused by miscommunication and maintain rapport.",
        "Master the art of turning miscommunications into opportunities for improved understanding and stronger connections.",
      ],
    },
    {
      lesson: "Expressing Concern and Empathy",
      milestones: [
        "Start by actively listening and showing empathy when someone shares their challenges or concerns.",
        "Practice using empathetic language and gestures to convey genuine care and support.",
        "Learn to offer assistance or resources to help address the concerns of others when appropriate.",
        "Develop the skill to communicate concern and empathy professionally, especially in workplace contexts.",
        "Master the ability to provide heartfelt and meaningful support to those in need, fostering trust and connection.",
      ],
    },
    {
      lesson: "Responding to Compliments",
      milestones: [
        "Begin by acknowledging compliments with a simple 'Thank you' and a gracious smile.",
        "Practice accepting compliments gracefully without downplaying or deflecting them.",
        "Learn to reciprocate compliments sincerely and authentically when appropriate.",
        "Develop the skill to handle compliments in professional settings with humility and professionalism.",
        "Master the art of receiving compliments in a way that enhances self-esteem and strengthens relationships.",
      ],
    },
    {
      lesson: "Elevator Pitch",
      milestones: [
        "Start by crafting a brief introduction that includes your name, profession, and a unique aspect of yourself.",
        "Practice delivering your elevator pitch confidently and concisely in networking and professional contexts.",
        "Learn to adapt your pitch for different audiences and situations while maintaining clarity and engagement.",
        "Develop the skill to use your elevator pitch effectively to make a memorable impression and initiate conversations.",
        "Master the ability to create compelling elevator pitches that highlight your value and goals in a concise manner.",
      ],
    },
    {
      lesson: "Dealing with Criticism",
      milestones: [
        "Begin by recognizing constructive criticism as an opportunity for growth and improvement.",
        "Practice listening to criticism without becoming defensive, seeking clarification when necessary.",
        "Learn to express gratitude for constructive feedback and a commitment to making necessary improvements.",
        "Develop the skill to handle criticism professionally in the workplace, using it as a tool for career development.",
        "Master the art of using criticism to continuously enhance your skills, performance, and relationships.",
      ],
    },
    {
      lesson: "Resolving Mispronunciations",
      milestones: [
        "Start by being aware of common mispronunciations in your own speech.",
        "Practice correcting mispronunciations gracefully when they occur, without embarrassment.",
        "Learn to offer assistance or corrections to others' mispronunciations in a tactful and helpful manner.",
        "Develop the skill to improve your pronunciation through consistent practice and self-awareness.",
        "Master the ability to communicate clearly and confidently by addressing and resolving mispronunciations effectively.",
      ],
    },
    {
      lesson: "Expressing Doubt and Uncertainty",
      milestones: [
        "Begin by recognizing situations where it's appropriate to express doubt or uncertainty.",
        "Practice conveying uncertainty honestly and transparently without undermining your credibility.",
        "Learn to ask clarifying questions or seek additional information when uncertain about a topic.",
        "Develop the skill to balance expressing doubt with a commitment to finding solutions or answers.",
        "Master the art of using doubt and uncertainty as opportunities for learning and collaboration, maintaining trust.",
      ],
    },
    {
      lesson: "Handling Disagreements in a Group",
      milestones: [
        "Start by participating actively in group discussions while respecting differing opinions.",
        "Practice acknowledging and validating opposing viewpoints with respect and empathy.",
        "Learn to constructively challenge ideas or opinions while maintaining a collaborative tone.",
        "Develop the skill to navigate group disagreements toward productive outcomes and consensus.",
        "Master the ability to contribute positively to group discussions, even in the face of differing opinions, fostering teamwork.",
      ],
    },
    {
      lesson: "Managing Conversational Dominance",
      milestones: [
        "Begin by self-monitoring your participation in conversations to avoid dominating or monopolizing them.",
        "Practice active listening and allowing others to speak, encouraging their contributions.",
        "Learn to balance your speaking time in group settings, ensuring equitable participation.",
        "Develop the skill to redirect conversations back to others when necessary to avoid over-dominance.",
        "Master the art of managing conversational dominance, creating inclusive and balanced discussions.",
      ],
    },
    {
      lesson: "Using Fillers Sparingly",
      milestones: [
        "Start by becoming aware of your use of filler words (e.g., 'uh,' 'um,' 'like') and hesitations in everyday speech.",
        "Practice minimizing the use of fillers and hesitations, focusing on speaking more clearly and confidently.",
        "Learn to pause briefly instead of using fillers, allowing yourself time to gather thoughts without distractions.",
        "Develop the skill to speak fluently and confidently with minimal filler words, improving communication effectiveness.",
        "Master the ability to maintain engaging conversations with clear and concise speech.",
      ],
    },
    {
      lesson: "Acknowledging Cultural Norms",
      milestones: [
        "Begin by educating yourself about cultural norms and practices in various regions and communities.",
        "Practice recognizing and respecting cultural differences when communicating across borders or with diverse groups.",
        "Learn to adapt your behavior and language to align with the cultural preferences and sensitivities of others.",
        "Develop the skill to navigate cross-cultural communication successfully, promoting cultural understanding and harmony.",
        "Master the art of communicating effectively while honoring and appreciating cultural diversity.",
      ],
    },
    {
      lesson: "Building Rapport",
      milestones: [
        "Start by establishing a positive attitude and approachable demeanor in your interactions with others.",
        "Practice using conversational techniques, such as active listening and empathy, to connect with people.",
        "Learn to find common ground and shared interests to build rapport and strengthen relationships.",
        "Develop the skill to adapt your communication style to connect with individuals from different backgrounds.",
        "Master the ability to foster genuine rapport and connection, creating meaningful and lasting relationships.",
      ],
    },
    {
      lesson: "Interpreting Body Language",
      milestones: [
        "Begin by studying and becoming familiar with common body language cues and gestures.",
        "Practice observing and interpreting body language during conversations to gain insight into emotions and intentions.",
        "Learn to use body language effectively to convey your own messages and emotions authentically.",
        "Develop the skill to detect non-verbal cues that may indicate discomfort or disagreement in others.",
        "Master the art of understanding and using body language as a valuable tool for communication and connection.",
      ],
    },
    {
      lesson: "Maintaining Professional Boundaries",
      milestones: [
        "Start by defining and recognizing the boundaries between personal and professional conversations.",
        "Practice keeping conversations strictly professional when necessary, especially in workplace settings.",
        "Learn to navigate conversations with colleagues or clients, maintaining respect and professionalism at all times.",
        "Develop the skill to set clear boundaries and redirect conversations back to professional topics when needed.",
        "Master the ability to balance professional and personal aspects of communication while upholding ethical standards.",
      ],
    },
    {
      lesson: "Expressing Regret",
      milestones: [
        "Begin by acknowledging and taking responsibility for mistakes or errors.",
        "Practice expressing genuine remorse and apologizing sincerely when a mistake has been made.",
        "Learn to use empathetic language and gestures to convey your regret and commitment to making amends.",
        "Develop the skill to express regret professionally and effectively, especially in workplace situations.",
        "Master the art of conveying regret in a way that rebuilds trust and maintains positive relationships.",
      ],
    },
    {
      lesson: "Dealing with Inquiries",
      milestones: [
        "Start by actively listening to questions and inquiries, ensuring you fully understand the context.",
        "Practice responding to inquiries promptly and respectfully, providing relevant information without oversharing.",
        "Learn to respect privacy and boundaries when handling sensitive or personal questions.",
        "Develop the skill to address inquiries diplomatically and professionally, even in challenging or sensitive situations.",
        "Master the ability to handle inquiries effectively, maintaining transparency and trust in communication.",
      ],
    },
    {
      lesson: "Active Reading and Listening",
      milestones: [
        "Begin by improving your reading and listening skills through regular practice and focus.",
        "Practice critical reading, extracting key information, and analyzing content for deeper understanding.",
        "Learn to actively listen in conversations, asking clarifying questions and seeking additional information.",
        "Develop the skill to synthesize and interpret information effectively from various sources and spoken words.",
        "Master the art of active reading and listening, enhancing your ability to comprehend and engage with others.",
      ],
    },
    {
      lesson: "Giving Public Speeches",
      milestones: [
        "Start by overcoming fear and anxiety related to public speaking through practice and confidence-building techniques.",
        "Practice structuring and delivering speeches that engage and inform your audience effectively.",
        "Learn to use visual aids, storytelling, and persuasive techniques to captivate your audience during speeches.",
        "Develop the skill to adapt your speaking style to different contexts, such as formal presentations or informal talks.",
        "Master the ability to deliver clear, impactful, and memorable public speeches that inspire and inform.",
      ],
    },
    {
      lesson: "Providing Clear Instructions",
      milestones: [
        "Begin by understanding the importance of clarity and simplicity when delivering instructions.",
        "Practice formulating concise and understandable instructions for various tasks or processes.",
        "Learn to anticipate potential questions or confusion and proactively address them in your instructions.",
        "Develop the skill to provide instructions in a way that empowers others to complete tasks accurately and efficiently.",
        "Master the art of delivering clear and effective instructions, facilitating smoother workflows and successful outcomes.",
      ],
    },
    {
      lesson: "Using Analogies",
      milestones: [
        "Start by recognizing situations where analogies can be helpful in conveying complex ideas or concepts.",
        "Practice creating and using analogies effectively to clarify and simplify information for others.",
        "Learn to select analogies that resonate with your audience and align with the topic of discussion.",
        "Develop the skill to explain abstract or unfamiliar concepts through relatable and vivid analogies.",
        "Master the ability to use analogies as powerful tools for enhancing understanding and communication.",
      ],
    },
    {
      lesson: "Resolving Linguistic Taboos",
      milestones: [
        "Begin by educating yourself on linguistic taboos and offensive language that may exist in different cultures or communities.",
        "Practice recognizing and avoiding offensive language and culturally insensitive expressions.",
        "Learn to address instances of linguistic taboos with sensitivity and a commitment to respectful communication.",
        "Develop the skill to navigate conversations without inadvertently using offensive language or causing discomfort.",
        "Master the art of promoting inclusive and respectful communication by actively addressing linguistic taboos.",
      ],
    },
    {
      lesson: "Expressing Agreement Diplomatically",
      milestones: [
        "Start by acknowledging and appreciating others' ideas or viewpoints when you agree with them.",
        "Practice expressing agreement diplomatically by providing additional insights or perspectives.",
        "Learn to use polite language and gestures to convey agreement while adding value to ongoing conversations.",
        "Develop the skill to balance agreement and constructive input to enhance the quality of discussions.",
        "Master the ability to foster collaborative and productive conversations through diplomatic agreement expressions.",
      ],
    },
    {
      lesson: "Conversational Cues",
      milestones: [
        "Begin by paying attention to non-verbal cues, such as body language and tone, that signal shifts in conversation dynamics.",
        "Practice recognizing conversational cues that indicate when it's appropriate to change the topic or wrap up a conversation.",
        "Learn to adapt your responses and contributions based on the cues and signals provided by your conversation partner(s).",
        "Develop the skill to navigate conversations smoothly, transitioning between topics and concluding discussions effectively.",
        "Master the art of being attuned to conversational cues, ensuring engaging and harmonious interactions.",
      ],
    },
    {
      lesson: "Cultural References",
      milestones: [
        "Start by immersing yourself in diverse cultural experiences and media to expand your cultural knowledge.",
        "Practice recognizing and using culturally relevant references in conversations to connect with others.",
        "Learn to select references that resonate with the cultural background and interests of your audience.",
        "Develop the skill to enhance cross-cultural communication by integrating culturally significant references.",
        "Master the ability to foster cultural understanding and bridge communication gaps through thoughtful cultural references.",
      ],
    },
    {
      lesson: "Expressing Curiosity",
      milestones: [
        "Begin by developing a genuine interest in a wide range of topics and a curiosity about others' perspectives.",
        "Practice asking questions that express your curiosity and engagement in the conversation.",
        "Learn to listen actively to responses and ask follow-up questions that delve deeper into the topic.",
        "Develop the skill to foster meaningful connections and build rapport by demonstrating sincere curiosity.",
        "Master the art of using curiosity as a driving force for enriching conversations and expanding your knowledge.",
      ],
    },
    {
      lesson: "Dealing with Multilingual Environments",
      milestones: [
        "Start by familiarizing yourself with the languages commonly spoken in multilingual environments.",
        "Practice navigating conversations where multiple languages are used, showing respect for linguistic diversity.",
        "Learn to adapt your language use to the preferences and abilities of those you are communicating with.",
        "Develop the skill to facilitate effective communication in multilingual settings, promoting inclusivity.",
        "Master the ability to engage confidently and respectfully in conversations regardless of the language spoken.",
      ],
    },
    {
      lesson: "Matching Register and Tone",
      milestones: [
        "Begin by understanding the importance of adapting your language register and tone based on the context and audience.",
        "Practice adjusting your level of formality or informality to align with the expectations of the communication situation.",
        "Learn to gauge the appropriate register and tone for various settings, such as professional, informal, or academic.",
        "Develop the skill to communicate effectively by matching your language style to the expectations of your audience.",
        "Master the art of using register and tone as strategic tools for successful communication in diverse contexts.",
      ],
    },
    {
      lesson: "Providing Clarity in Writing",
      milestones: [
        "Start by recognizing the significance of clear and concise writing in various forms of communication.",
        "Practice organizing thoughts and ideas logically when composing messages, emails, or documents.",
        "Learn to use proper formatting, punctuation, and grammar to enhance the clarity of your written communication.",
        "Develop the skill to proofread and edit your written content for errors and inconsistencies.",
        "Master the ability to deliver written messages and documents that are easily understood and professional in style.",
      ],
    },
    {
      lesson: "Handling Social Media Communication",
      milestones: [
        "Begin by familiarizing yourself with the etiquette and norms of communication on different social media platforms.",
        "Practice using social media platforms to engage with others respectfully and professionally.",
        "Learn to balance personal and professional content while maintaining a positive online presence.",
        "Develop the skill to handle comments, feedback, and interactions on social media with diplomacy and tact.",
        "Master the art of effective and responsible communication in the dynamic realm of social media.",
      ],
    },
    {
      lesson: "Acknowledging Personal Boundaries",
      milestones: [
        "Start by recognizing the concept of personal space and individual boundaries in different social contexts.",
        "Practice respecting personal boundaries by maintaining an appropriate physical distance and avoiding intrusive behavior.",
        "Learn to adapt your language and gestures to ensure comfort and respect for personal boundaries.",
        "Develop the skill to navigate social situations with awareness of personal space preferences and boundaries.",
        "Master the ability to build trust and positive relationships by demonstrating respect for others' personal boundaries.",
      ],
    },
    {
      lesson: "Maintaining Professional Etiquette",
      milestones: [
        "Begin by familiarizing yourself with the professional conduct and etiquette expected in your workplace or industry.",
        "Practice consistently adhering to professional standards, including punctuality, dress code, and respectful behavior.",
        "Learn to communicate professionally through emails, meetings, and interactions with colleagues and clients.",
        "Develop the skill to navigate workplace dynamics and relationships with professionalism and courtesy.",
        "Master the art of upholding a high level of professional etiquette that enhances your reputation and career prospects.",
      ],
    },
    {
      lesson: "Cultural References in Humor",
      milestones: [
        "Start by gaining an understanding of different cultures' humor styles, references, and sensitivities.",
        "Practice using culturally specific humor appropriately to engage and connect with diverse audiences.",
        "Learn to select humor that aligns with the cultural backgrounds and preferences of your listeners.",
        "Develop the skill to integrate cultural references seamlessly into humor without causing offense.",
        "Master the ability to use humor as a bridge that fosters cultural understanding and laughter across boundaries.",
      ],
    },
    {
      lesson: "Sensitivity to Disabilities",
      milestones: [
        "Begin by educating yourself about various disabilities and the challenges individuals with disabilities may face.",
        "Practice using empathetic language and gestures when communicating with individuals with disabilities.",
        "Learn to offer assistance or support in a respectful and non-patronizing manner when appropriate.",
        "Develop the skill to create inclusive environments where individuals with disabilities feel valued and heard.",
        "Master the art of promoting accessibility and sensitivity in communication to build inclusive communities.",
      ],
    },
    {
      lesson: "Managing Personal Presentation",
      milestones: [
        "Start by evaluating your personal language and appearance in professional and social settings.",
        "Practice presenting yourself professionally through both verbal and non-verbal cues, including attire and grooming.",
        "Learn to adapt your communication style to match the level of formality expected in various contexts.",
        "Develop the skill to convey competence and confidence through your language and personal presentation.",
        "Master the ability to make a positive impression and convey professionalism through your overall presentation.",
      ],
    },
    {
      lesson: "Navigating Hierarchies in the Workplace",
      milestones: [
        "Begin by understanding the organizational hierarchy and power dynamics within your workplace.",
        "Practice adapting your language and communication style based on your interactions with colleagues at different levels.",
        "Learn to communicate effectively with superiors, peers, and subordinates while respecting hierarchy.",
        "Develop the skill to navigate workplace hierarchies to build positive relationships and achieve goals.",
        "Master the art of using communication strategically to thrive within workplace hierarchies.",
      ],
    },
    {
      lesson: "Expressing Urgency",
      milestones: [
        "Start by recognizing situations where conveying urgency is necessary to communicate time-sensitive matters.",
        "Practice using clear and persuasive language to express the importance and time-critical nature of a situation.",
        "Learn to prioritize and communicate urgent matters effectively to avoid misunderstandings or delays.",
        "Develop the skill to handle urgent situations professionally, considering the impact on others' schedules.",
        "Master the ability to convey urgency without causing unnecessary stress or panic, ensuring efficient responses.",
      ],
    },
    {
      lesson: "Using Jargon Appropriately",
      milestones: [
        "Begin by gaining proficiency in industry-specific or technical jargon relevant to your field.",
        "Practice using jargon appropriately and sparingly, considering the comprehension level of your audience.",
        "Learn to provide explanations or context when using jargon in conversations to ensure understanding.",
        "Develop the skill to communicate effectively with colleagues and stakeholders by using industry-specific terminology.",
        "Master the art of leveraging jargon to enhance your credibility and expertise in professional settings.",
      ],
    },
    {
      lesson: "Addressing Elders and Authority Figures",
      milestones: [
        "Start by understanding cultural and societal norms related to addressing elders and authority figures.",
        "Practice using appropriate language and gestures to show respect when interacting with elders and superiors.",
        "Learn to adapt your communication style based on the level of formality expected when addressing authority figures.",
        "Develop the skill to convey respect and professionalism when communicating with individuals in positions of authority.",
        "Master the ability to build positive and respectful relationships with elders and authority figures through effective communication.",
      ],
    },
    {
      lesson: "Persuasive Communication",
      milestones: [
        "Begin by studying persuasive communication techniques, including rhetoric and persuasive language strategies.",
        "Practice crafting persuasive messages, speeches, or arguments that are compelling and convincing.",
        "Learn to tailor your persuasive communication approach to different audiences and contexts.",
        "Develop the skill to handle objections and counterarguments effectively, reinforcing your position persuasively.",
        "Master the art of persuasive communication, influencing others positively and achieving desired outcomes.",
      ],
    },
    {
      lesson: "Handling Sensitive Conversations",
      milestones: [
        "Start by acknowledging the sensitivity of the topic and the potential impact on individuals involved.",
        "Practice active listening and empathy to create a safe and supportive environment for discussion.",
        "Learn to choose words and tone carefully, addressing the sensitive topic with compassion and respect.",
        "Develop the skill to navigate difficult conversations, focusing on solutions and understanding.",
        "Master the ability to foster healing and resolution through sensitive and empathetic communication.",
      ],
    },
    {
      lesson: "Public Relations and Crisis Communication",
      milestones: [
        "Begin by understanding the principles of crisis communication and public relations strategies.",
        "Practice timely and transparent communication during crises, providing information and reassurance.",
        "Learn to handle media inquiries and public statements professionally and effectively.",
        "Develop the skill to maintain a positive public image and mitigate reputational damage through crisis communication.",
        "Master the art of crisis communication, ensuring the organization's reputation remains intact during challenging times.",
      ],
    },
    {
      lesson: "Giving Constructive Feedback to Superiors",
      milestones: [
        "Start by recognizing the importance of constructive feedback in professional growth and development.",
        "Practice framing feedback professionally and diplomatically when providing input to superiors.",
        "Learn to focus on specific examples and suggestions for improvement when giving feedback.",
        "Develop the skill to initiate constructive conversations with superiors, fostering a culture of continuous improvement.",
        "Master the ability to provide feedback that enhances both individual and organizational success.",
      ],
    },
    {
      lesson: "Managing Speech Rate",
      milestones: [
        "Begin by assessing your natural speech rate and its impact on listener comprehension.",
        "Practice adjusting your speech rate to match the comprehension level and comfort of your audience.",
        "Learn to speak clearly and enunciate words when communicating with individuals who may require slower speech.",
        "Develop the skill to maintain a balanced speech rate that ensures effective communication with diverse listeners.",
        "Master the art of adapting your speech rate as a tool for clear and engaging communication.",
      ],
    },
    {
      lesson: "Interpreting Cultural Symbols",
      milestones: [
        "Start by studying and familiarizing yourself with symbols and gestures that hold cultural significance.",
        "Practice recognizing and respecting these symbols and gestures when interacting with individuals from different cultures.",
        "Learn to avoid unintentional offense by understanding the cultural context behind these symbols.",
        "Develop the skill to communicate cross-culturally with sensitivity to symbols and gestures.",
        "Master the ability to bridge cultural gaps and build trust through respectful interpretation of cultural symbols.",
      ],
    },
    {
      lesson: "Using Proper Titles in Writing",
      milestones: [
        "Begin by understanding the conventions and expectations of using formal titles in written communication.",
        "Practice addressing individuals formally by their appropriate titles in emails, letters, and documents.",
        "Learn to research and verify the correct titles and forms of address for different recipients.",
        "Develop the skill to convey respect and professionalism through the appropriate use of titles in writing.",
        "Master the art of demonstrating courtesy and etiquette in written communication by addressing individuals correctly.",
      ],
    },
    {
      lesson: "Multimodal Communication",
      milestones: [
        "Start by recognizing the value of incorporating visual aids, gestures, and speech in communication.",
        "Practice using visual aids effectively to enhance the clarity and impact of your messages.",
        "Learn to coordinate verbal and non-verbal elements for more engaging and persuasive communication.",
        "Develop the skill to adapt your communication style to different settings, maximizing the effectiveness of multimodal communication.",
        "Master the ability to convey complex information and ideas through a combination of communication modalities.",
      ],
    },
    {
      lesson: "Balancing Online and Offline Communication",
      milestones: [
        "Begin by evaluating your communication habits in both virtual and physical environments.",
        "Practice maintaining a healthy balance between online and offline interactions to meet social and professional needs.",
        "Learn to adapt your communication style to suit the medium, considering factors like tone and etiquette.",
        "Develop the skill to manage time and attention effectively in both virtual and physical spaces.",
        "Master the art of thriving in a connected world by mastering the nuances of online and offline communication.",
      ],
    },
    {
      lesson: "Handling Group Facilitation",
      milestones: [
        "Start by gaining an understanding of effective group facilitation techniques and strategies.",
        "Practice leading and moderating group discussions and meetings, ensuring active participation and engagement.",
        "Learn to manage group dynamics, foster collaboration, and steer discussions toward productive outcomes.",
        "Develop the skill to handle challenges and conflicts within groups while maintaining a positive atmosphere.",
        "Master the ability to facilitate groups with confidence, achieving objectives and building strong team dynamics.",
      ],
    },
    {
      lesson: "Expressing Ethical Concerns",
      milestones: [
        "Begin by reflecting on your personal values and ethical principles that guide your behavior and communication.",
        "Practice addressing ethical concerns in a principled and respectful manner, considering the well-being of all parties involved.",
        "Learn to articulate ethical dilemmas clearly and offer constructive solutions or alternatives.",
        "Develop the skill to engage in ethical discussions and decision-making, even in complex or sensitive situations.",
        "Master the art of upholding ethical standards and promoting ethical behavior through your communication.",
      ],
    },
    {
      lesson: "Respecting Privacy and Confidentiality Online",
      milestones: [
        "Start by understanding the importance of safeguarding personal and sensitive information in digital communication.",
        "Practice using secure and private communication channels when handling confidential matters online.",
        "Learn to recognize and avoid sharing personal information without consent or inappropriately.",
        "Develop the skill to maintain privacy and confidentiality in online interactions, respecting trust and boundaries.",
        "Master the ability to uphold ethical standards and protect sensitive data in digital communication.",
      ],
    },
    {
      lesson: "Articulating Complex Ideas",
      milestones: [
        "Begin by identifying complex concepts or ideas that need to be communicated.",
        "Practice breaking down complex ideas into simpler, understandable language for various audiences.",
        "Learn to use analogies, metaphors, and examples to clarify complex concepts effectively.",
        "Develop the skill to adapt your explanations to match the comprehension level of your audience.",
        "Master the art of articulating complex ideas in a clear and accessible manner, fostering understanding and engagement.",
      ],
    },
    {
      lesson: "Inclusive Language",
      milestones: [
        "Start by recognizing the importance of using language that is inclusive of all genders, ethnicities, and identities.",
        "Practice incorporating inclusive language into your communication, such as using gender-neutral terms and respecting pronoun preferences.",
        "Learn to avoid stereotypes, biases, and language that may exclude or marginalize certain groups.",
        "Develop the skill to create an inclusive and welcoming environment through your choice of language.",
        "Master the ability to promote diversity, equity, and inclusion through thoughtful and inclusive language use.",
      ],
    },
    {
      lesson: "Dealing with Unintended Offense",
      milestones: [
        "Begin by acknowledging the possibility of unintentionally causing offense through your language or actions.",
        "Practice apologizing sincerely and promptly when you realize that your words or behavior have been offensive.",
        "Learn to listen actively to feedback and engage in conversations to understand and learn from your mistakes.",
        "Develop the skill to foster reconciliation and growth by addressing unintentional offense with humility and empathy.",
        "Master the art of continuously improving your communication to minimize unintended offense and promote positive interactions.",
      ],
    },
    {
      lesson: "Expressing Appreciation for Feedback",
      milestones: [
        "Start by valuing feedback as an opportunity for personal and professional growth.",
        "Practice acknowledging feedback, whether positive or critical, with gratitude and professionalism.",
        "Learn to express your appreciation for feedback by implementing constructive suggestions and demonstrating improvement.",
        "Develop the skill to create a culture of feedback exchange, encouraging open and honest communication.",
        "Master the ability to leverage feedback as a valuable resource for self-improvement and success.",
      ],
    },
    {
      lesson: "Managing Public Speaking Anxiety",
      milestones: [
        "Begin by recognizing and accepting your anxiety or nervousness about public speaking.",
        "Practice relaxation techniques, such as deep breathing and visualization, to manage anxiety before and during public speaking engagements.",
        "Learn to prepare thoroughly and rehearse your speeches or presentations to boost your confidence.",
        "Develop the skill to engage the audience effectively and adapt to unexpected challenges during public speaking.",
        "Master the art of overcoming public speaking anxiety, delivering confident and impactful presentations.",
      ],
    },
    {
      lesson: "Navigating Cross-Generational Communication",
      milestones: [
        "Start by understanding the generational differences in communication styles, values, and preferences.",
        "Practice adapting your communication style when interacting with different age groups, showing respect and flexibility.",
        "Learn to bridge generation gaps through active listening and seeking common ground.",
        "Develop the skill to foster intergenerational understanding and collaboration in both personal and professional settings.",
        "Master the ability to communicate effectively and build connections across generations.",
      ],
    },
    {
      lesson: "Using Metacommunication",
      milestones: [
        "Begin by reflecting on your own communication processes and behaviors, considering their impact on others.",
        "Practice discussing communication dynamics openly with others to gain insights and improve interactions.",
        "Learn to provide constructive feedback and receive it graciously, promoting mutual understanding and growth.",
        "Develop the skill to adapt your communication strategies based on metacommunication insights.",
        "Master the art of continually refining your communication skills through self-awareness and metacommunication.",
      ],
    },
    {
      lesson: "Advocating for Oneself",
      milestones: [
        "Start by recognizing the importance of assertively expressing your needs, desires, and boundaries.",
        "Practice articulating your needs and boundaries respectfully and confidently in various contexts.",
        "Learn to negotiate and advocate for yourself effectively while considering the needs and perspectives of others.",
        "Develop the skill to create mutually beneficial outcomes through assertive communication.",
        "Master the ability to assertively advocate for yourself in a way that promotes your well-being and success.",
      ],
    },
    {
      lesson: "Continual Adaptation",
      milestones: [
        "Begin by embracing the idea that sociolinguistic competence is an ongoing process of learning and adapting.",
        "Practice staying informed about evolving social and cultural norms that affect communication.",
        "Learn to adjust your communication style and strategies in response to changing contexts and societal shifts.",
        "Develop the skill to remain flexible and open to new perspectives and approaches in communication.",
        "Master the art of continual adaptation, ensuring your sociolinguistic competence remains relevant and effective.",
      ],
    },
  ];

  const communicationProgressCategories = [
    { name: "Politeness and Courtesy", progress: 0 },
    { name: "Active Listening and Engagement", progress: 0 },
    { name: "Empathy and Understanding", progress: 0 },
    { name: "Clarity and Articulation", progress: 0 },
    { name: "Adaptability and Flexibility", progress: 0 },
    { name: "Conflict Resolution and Diplomacy", progress: 0 },
    { name: "Storytelling and Engagement", progress: 0 },
    { name: "Professional Communication", progress: 0 },
    { name: "Cultural Sensitivity and Inclusivity", progress: 0 },
    { name: "Public Speaking and Presentation", progress: 0 },
  ];
  console.log(communicationProgressCategories);

  const [desiredRole, setDesiredRole] = useState("");
  const [selectedGoal, setSelectedGoal] = useState("");

  const handleDesiredRoleInputChange = (e) => {
    setDesiredRole(e.target.value);
    onDesiredRoleChange(e.target.value); // Call the callback function
  };

  const handleSelectedChange = (event) => {
    const newGoal = event.target.value;
    setSelectedGoal(newGoal);
    onSelectedGoalChange(newGoal); // Existing callback for selected goal
  
    // Find the milestones based on the new goal
    const newSelectedLessonName = newGoal.split(":")[0].trim();
    const newSelectedMilestones = sociolinguisticMilestones.find(
      (lesson) => lesson.lesson === newSelectedLessonName
    )?.milestones || [];
  
    // Call the callback function to send milestones back to the parent
    onSelectedMilestonesChange(newSelectedMilestones);
  };
  

  const selectedLessonName = selectedGoal.split(":")[0].trim();

  // Filter the milestones based on the selected lesson
  const selectedMilestones =
    sociolinguisticMilestones.find(
      (lesson) => lesson.lesson === selectedLessonName
    )?.milestones || [];

  return (
    <div className="CTaG-Container">
      <input
        className="CTaG-DesiredRoleInput"
        type="text"
        value={desiredRole}
        onChange={handleDesiredRoleInputChange}
        placeholder="Enter desired role (i.e.: doctor, chef, frontend developer, etc.)"
      />
      <h2 className="CTaG-Header">Select a Conversation Goal</h2>
      <select
        className="CTaG-WRG-Select"
        value={selectedGoal}
        onChange={handleSelectedChange}
      >
        <option value="">Select a goal (easier to harder)</option>
        {workRelatedGoals.map((goal, index) => (
          <option key={index} value={goal}>
            {goal}
          </option>
        ))}
      </select>
      {selectedGoal && (
        <div className="CTaG-Milestones">
          <h2 className="CTaG-Header">Milestones for {selectedLessonName}</h2>
          <ul className="CTaG-UnorderedList">
            {selectedMilestones.map((milestone, index) => (
              <li className="CTaG-ListItem" key={index}>
                {milestone}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ConversationTopicsandGoals;
